import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom-v5-compat';
import { FormikPasswordField } from 'shared/form/FormikPasswordField';
import { useQuery } from 'shared/helpers/RouteHelpers';
import {
  composeValidators,
  maxLength,
  required,
  Value,
} from 'shared/utils/ValidatorUtils';
import { yupString } from 'shared/utils/YupUtils';
import { ref } from 'yup';
import { PasswordValidationStepper } from '../shared/ui/PasswordValidationStepper';
import { useResetPasswordAPI } from './data/ResetPasswordAPI';
import { ResetPasswordDTO, resetPasswordSchema } from './data/ResetPasswordDTO';

const formSchema = resetPasswordSchema.shape({
  password_confirm: yupString().oneOf(
    [ref('password')],
    'Passwords do not match',
  ),
});

export function ResetPasswordForm() {
  const [{ token }] = useQuery();
  const { resetPassword } = useResetPasswordAPI();
  const { addSnackbar } = useSnackbarStack();
  const navigate = useNavigate();

  const form = useFormikEnhanced<ResetPasswordDTO, unknown>({
    initialValues: {
      password: '',
    },
    validationSchema: formSchema,
    onSubmit: ({ password }) =>
      resetPassword({
        password,
        token,
      }),
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
    onSubmitSuccess: () => {
      navigate('/signin');
      addSnackbar('Password sucessfully changed ', { variant: 'success' });
    },
  });

  const {
    values: { password },
  } = form;

  return (
    <FormikProvider value={form}>
      <Form autoComplete="off">
        <Box padding="medium">
          <Stack space="small">
            <Typography variant="h2" align="left">
              Reset Password
            </Typography>

            <Stack space="large">
              <Stack space="small">
                <Stack space="xsmall">
                  <FormikPasswordField
                    fullWidth={true}
                    name="password"
                    label="New Password"
                    validate={composeValidators(required, (value: Value) =>
                      maxLength(value, 30),
                    )}
                    autoComplete="new-password"
                  />
                  <PasswordValidationStepper value={password} />
                </Stack>
                <FormikPasswordField
                  name="password_confirm"
                  label="Re-enter Your New Password"
                  fullWidth={true}
                  autoComplete="new-password"
                />
              </Stack>

              <Button
                type="submit"
                size="large"
                fullWidth={true}
                pending={form.isSubmitting}
                disabled={!form.isValid || !form.dirty}
              >
                Reset Password
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Form>
    </FormikProvider>
  );
}
