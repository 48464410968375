import { CircularProgress } from '@material-ui/core';
import { Color, useSnackbarStack } from '@superdispatch/ui';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { isPreviewVariant, usePreviewFile } from './OrderPreviewAPI';

export function OrderPreviewPage() {
  const navigate = useNavigate();
  const { type = '', orderID } = useParams<{ type: string; orderID: string }>();
  const { addSnackbar } = useSnackbarStack();
  const { data: previewFile, error } = usePreviewFile(
    isPreviewVariant(type) ? { orderId: Number(orderID), type } : undefined,
  );

  const previewURL = useMemo(
    () => (previewFile ? URL.createObjectURL(previewFile) : undefined),
    [previewFile],
  );

  useEffect(() => {
    if (error) {
      addSnackbar(error.message, { variant: 'error' });
      navigate(-1);
    }
  }, [error, navigate, addSnackbar]);
  return (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        background: Color.White,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {previewURL ? (
        <iframe title="Preview" src={previewURL} width="100%" height="100%" />
      ) : (
        <CircularProgress
          size={100}
          color="inherit"
          aria-label="loading"
          variant="indeterminate"
        />
      )}
    </div>
  );
}
