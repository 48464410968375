import { useSnackbarStack } from '@superdispatch/ui';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { LoadingContainer } from './LoadingContainer';

function usePaymentLink() {
  const { addSnackbar } = useSnackbarStack();
  const { requestResource } = useAPI();
  const navigate = useNavigate();
  return useAPIQuery(
    'payment-link',
    () =>
      requestResource(
        'GET /internal/billing/payments/form',
        (data) => data as { url: string },
      ),
    {
      onSuccess({ url }) {
        window.location.assign(url);
      },
      onError(error) {
        addSnackbar(error.message, { variant: 'error' });
        navigate('/', { replace: true });
      },
    },
  );
}

export function BillingPaymentForm() {
  usePaymentLink();
  return <LoadingContainer />;
}
