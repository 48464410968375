import { ProtectedRoute } from 'AppRouter';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { CreateInternalCarrierPage } from './create/CreateInternalCarrierPage';
import { EditInternalCarrierPage } from './edit/EditInternalCarrierPage';
import { ManageCarriersListPage } from './list/ManageCarriersListPage';
import { CarrierProfilePage } from './view/CarrierProfilePage';

export function ManageCarriersContainer() {
  useDocumentTitle('Manage Carriers');
  useTrackEvent('[STMS] Viewed Manage Carriers');

  return (
    <Routes>
      <Route path="usdot/:usdot" element={<CarrierProfilePage />} />
      <Route path=":guid" element={<CarrierProfilePage />} />
      <Route
        path="create"
        element={
          <ProtectedRoute>
            <CreateInternalCarrierPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:guid"
        element={
          <ProtectedRoute>
            <EditInternalCarrierPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <ManageCarriersListPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
