import { IconButton, Toolbar, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom-v5-compat';
import { PageLayout } from 'shared/layout/PageLayout';
import { SubscriptionPaymentForm } from '../SubscriptionPaymentForm';

export function SubscriptionPlansExpressPage() {
  const navigate = useNavigate();
  return (
    <PageLayout
      contentBackground={Color.White}
      header={
        <Box borderBottomColor="Silver400" borderBottomWidth="small">
          <Toolbar>
            <IconButton aria-label="go back" onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>

            <Typography variant="h2">Add Payment Information</Typography>
          </Toolbar>
        </Box>
      }
    >
      <Box padding="medium">
        <SubscriptionPaymentForm />
      </Box>
    </PageLayout>
  );
}
