import { Box, debounce } from '@material-ui/core';
import { Dehaze, ViewAgendaOutlined } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useValueObserver } from '@superdispatch/hooks';
import { Color, Column, Columns, useResponsiveValue } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { OrderListActions } from 'orders/core/actions/OrderListActions';
import { useSearchPeriod } from 'orders/core/list/OrderListSearchPeriod';
import { useSearchType } from 'orders/core/list/OrderListSearchType';
import {
  OrderListCardSortBar,
  OrdersListCardView,
} from 'orders/core/list/OrdersListCardView';
import { OrdersEmptyState } from 'orders/OrdersEmptyState';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useOrderCounts } from 'shared/api/OrderCountAPI';
import { useUserState } from 'shared/data/AppUserState';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { logError } from 'shared/helpers/MonitoringService';
import { useSearchInput } from 'shared/helpers/ReactHelpers';
import { useQuery, useQueryParams } from 'shared/helpers/RouteHelpers';
import { SourceManager } from 'shared/helpers/SourceManager';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import Order from 'shared/types/order';
import { isRouteStatus } from 'shared/types/routeStatus';
import styled from 'styled-components';
import { OrderTable } from '../core';
import { OrderActions } from '../core/actions/OrderActions';
import {
  OrderActionCompletePayload,
  OrderActionsProvider,
} from '../core/actions/OrderActionsContext';
import {
  BulkActionType,
  OrderBulkActions,
} from '../core/bulk-actions/OrderBulkActions';
import { OrderListRateBanner } from '../core/list/OrderListRateBanner';
import { OrdersListFooter } from '../core/list/OrdersListFooter';
import {
  getOrdersListHeaderText,
  OrdersListHeader,
} from '../core/list/OrdersListHeader';
import { useOrders, useOrdersCache, useSearchBody } from '../data/OrderAPI';
import { OrderListPageParamsDTO } from '../data/OrderListPageParamsDTO';
import { OrderListFilter } from './OrderListFilter';
import { OrdersListPostedEmptyState } from './OrdersListPostedEmptyState';
import { OrdersListTabs } from './OrdersListTabs';

export const ORDER_VIEW_KEY = 'order_view_key';

function useQueryNormalizer() {
  const [{ orderFilter, filters }, setQuery] = useQuery();

  useEffect(() => {
    if (typeof orderFilter === 'string') {
      try {
        const decodedValue = decodeURIComponent(orderFilter);
        const oldValue = JSON.parse(decodedValue) as Array<{
          type: string;
          value: unknown[];
        }>;
        const next = oldValue.reduce(
          (acc, { type, value }) => ({ ...acc, [type]: value }),
          {},
        );

        setQuery({ ...next, orderFilter: undefined }, true);
      } catch (error: unknown) {
        setQuery({ orderFilter: undefined }, true);
        logError(error, 'OrdersList#normalize');
      }
    }

    if (Array.isArray(filters)) {
      const next = filters.reduce(
        (acc, { type, value }) => ({ ...acc, [type]: value }),
        {},
      );

      setQuery({ ...next, filters: undefined }, true);
    }
  }, [filters, orderFilter, setQuery]);
}

const Wrap = styled.div<{ collapse: boolean; height: string }>`
  min-height: ${({ height }) => height};
  box-sizing: content-box;
  display: flex;
  align-items: center;
  background: ${Color.White};
  transition: all 0.3s ease;
  opacity: 1;
  flex-direction: column;

  ${({ collapse }) =>
    collapse &&
    'height: 0; min-height: 0; opacity: 0; padding: 0; & div { padding: 0; }'};
`;

const StyledBox = styled(Box)`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  position: relative;
`;

const StyledToggleButton = styled(ToggleButton)`
  color: ${Color.Dark100};
  & {
    border: 1px solid ${Color.Silver500};
  }
  &.Mui-selected {
    background: transparent;
    color: ${Color.Dark500};
  }
`;

export function OrdersListPage() {
  const navigate = useNavigate();
  const { status: statusParam } = useParams<{ status?: string }>();
  const status = isRouteStatus(statusParam) ? statusParam : undefined;
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const orderViewKey = useStorageValue(ORDER_VIEW_KEY) || 'card';
  const { data: counts } = useOrderCounts();

  const listRef = useRef<HTMLDivElement>(null);
  const { user } = useUserState();

  const [query, setQuery] = useState<string>('');
  const [orderView, setOrderView] = useState<string>(orderViewKey);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [scrolDirection, setScrollDirection] = useState('up');
  const searchType = useSearchType();
  const searchPeriod = useSearchPeriod();

  const debouncedQuery = useSearchInput(query);
  const queryParams = {
    size: 20,
    sort: ['changedAt', 'DESC'],
    query_field:
      debouncedQuery && debouncedQuery.length > 2 ? searchType : undefined,
    period: '',
    page: 1,
  };

  if (debouncedQuery && debouncedQuery.length > 2 && searchPeriod !== 'all') {
    queryParams.period = searchPeriod;
  }

  const [params, setParams] = useQueryParams(
    OrderListPageParamsDTO,
    queryParams,
  );

  const { sort, limit, size, page, ...filters } = params;

  const searchBody = useSearchBody({
    filters,
    searchString: debouncedQuery,
    status,
  });

  const pageParams = useMemo(
    () => ({ limit, size, sort: sort.join(',') }),
    [limit, size, sort],
  );

  const { data, isPreviousData } = useOrders({
    page: page - 1,
    status,
    searchBody,
    params: pageParams,
  });

  const { invalidateOrders } = useOrdersCache();
  const { updateOrderCacheList, updateOrderCacheSearch } = useOrdersCache();

  const updateOrderCache = useCallback(
    (order: Order) => {
      if (searchBody) {
        updateOrderCacheSearch(order, {
          page,
          searchBody,
          params: pageParams,
        });
      } else {
        updateOrderCacheList(order, {
          page,
          status,
          params: pageParams,
        });
      }
    },
    [searchBody, page, status, pageParams],
  );

  const { objects: orders = [], pagination } = data || {};
  useQueryNormalizer();

  useDocumentTitle(getOrdersListHeaderText(status));

  useEffect(() => {
    if (debouncedQuery) {
      setParams({ page: 1 });
      trackEventLegacy('Searched Orders', {
        text: debouncedQuery,
        searchType,
        searchPeriod,
      });
    }
  }, [debouncedQuery]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo(0, 0);
    }
  }, [orders]);

  useEffect(() => {
    if (platform === 'mobile') {
      writeStorageItem(ORDER_VIEW_KEY, 'card');
      setOrderView('card');
    }
  }, [platform]);

  // Reset page number when status, searchType or searchPeriod changes
  useValueObserver([status, searchType, searchPeriod].join(), () => {
    setParams({ page: 1 });
  });

  /**
   * We need to store whole Order object because:
   * during navigation through the pages, the orders array is resets
   */
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);

  // Updates the selected orders based on the changes in the orders array.
  useValueObserver(orders, () => {
    setSelectedOrders((selectedOutdatedOrders) => {
      return selectedOutdatedOrders.map((order) => {
        const updatedOrder = orders.find(
          (newOrder) => newOrder.id === order.id,
        );
        return updatedOrder || order;
      });
    });
  });

  function toggleSelectedOrder(id: number) {
    setSelectedOrders((prevOrders) => {
      const isOrderSelected = prevOrders.some((order) => order.id === id);
      if (isOrderSelected) {
        // If the order is already selected, remove it from the array
        return prevOrders.filter((order) => order.id !== id);
      }
      // If the order is not selected, add it to the array
      const orderToAdd = orders.find((order) => order.id === id);
      return orderToAdd ? [...prevOrders, orderToAdd] : prevOrders;
    });
  }
  function handlePageChange({ selected: selectedPage }: { selected: number }) {
    setParams({ page: selectedPage + 1 });
  }

  function handlePageSizeChange(pageSize: { value: number }) {
    setParams({ size: pageSize.value, page: 1 });
  }

  function handleSort(key: string, sortOrder: string) {
    setParams({ sort: [key, sortOrder], page: 1 });
  }

  const handleSearch = useCallback(
    (searchQuery: string) => {
      setQuery(searchQuery);
    },
    [setQuery],
  );

  function handleSingleActionComplete({
    type,
    payload: { order, updatedOrder },
  }: OrderActionCompletePayload) {
    setSelectedOrders([]);
    switch (type) {
      case 'duplicate':
        if (updatedOrder) {
          navigate(`/orders/view/${updatedOrder.guid}`);
        }
        break;
      case 'cancel_offer': {
        const exceptionalStatuses = ['posted_to_cd', 'flagged', 'archived'];

        if (status && !exceptionalStatuses.includes(status)) {
          navigate(`/orders/view/${order.guid}`);
        } else if (updatedOrder) {
          updateOrderCache(updatedOrder);
        }
        break;
      }
      default:
        if (updatedOrder) {
          updateOrderCache(updatedOrder);
        }
    }

    void invalidateOrders();
  }

  function handleBulkActionComplete(_type: BulkActionType) {
    void invalidateOrders();
    setSelectedOrders([]);
  }

  const handleSetOrderView = (
    event: React.MouseEvent<HTMLElement>,
    newOrderView: string | null,
  ) => {
    if (event.target && newOrderView !== null) {
      writeStorageItem(ORDER_VIEW_KEY, newOrderView);
      setOrderView(newOrderView);
      trackEventLegacy('Clicked on change orders view', {
        selectedView: newOrderView,
      });
    }
  };

  if (pagination?.total_pages === 0 && counts?.total === 0) {
    return <OrdersEmptyState />;
  }

  const handleScroll = debounce((event) => {
    const { scrollTop } = event.target;

    const scrollMargin = Math.abs(scrollTop - lastScrollPosition);

    if (scrollTop >= lastScrollPosition && scrollMargin > 200) {
      setScrollDirection('down');
    }
    if (scrollTop < lastScrollPosition && scrollMargin > 200) {
      setScrollDirection('up');
    }
    if (scrollMargin > 200) {
      setLastScrollPosition(scrollTop);
    }
  }, 20);

  return (
    <SourceManager primarySource="Order List">
      <PageLayout
        header={
          <OrdersListHeader
            status={status}
            query={query}
            onSearch={handleSearch}
          />
        }
      >
        <Wrap
          height={
            orderView === 'card' && platform === 'mobile' ? '135px' : 'unset'
          }
          collapse={
            platform === 'mobile' &&
            orderView === 'card' &&
            scrolDirection === 'down'
          }
        >
          <StyledBox>
            <Columns align="center">
              <Column width="fluid">
                {selectedOrders.length > 0 ? (
                  <OrderBulkActions
                    source="Order List"
                    orders={selectedOrders}
                    onActionComplete={handleBulkActionComplete}
                  />
                ) : (
                  <Columns align="center" space="xsmall">
                    <Column width="content">
                      <TextBox color="secondary">
                        {pagination?.total_objects} orders
                      </TextBox>
                    </Column>

                    <Column>
                      <OrderListFilter
                        status={status}
                        filters={filters}
                        onChange={(key, value) => {
                          setParams({ [key]: value, page: 1 });
                        }}
                        onRemove={(key) => {
                          setParams({ [key]: undefined, page: 1 });
                        }}
                      />
                    </Column>
                  </Columns>
                )}
              </Column>
              <Column width="content">
                {platform !== 'mobile' &&
                  user?.shipper.subscription_plan === 'ADVANCED' && (
                    <ToggleButtonGroup
                      exclusive={true}
                      value={orderView}
                      onChange={handleSetOrderView}
                      aria-label="order view toggle"
                    >
                      <StyledToggleButton value="card" aria-label="order card">
                        <ViewAgendaOutlined fontSize="small" />
                      </StyledToggleButton>
                      <StyledToggleButton
                        value="table"
                        aria-label="order table"
                      >
                        <Dehaze fontSize="small" />
                      </StyledToggleButton>
                    </ToggleButtonGroup>
                  )}
              </Column>
            </Columns>
          </StyledBox>

          {status === 'delivered' && <OrderListRateBanner />}
        </Wrap>

        <Box bgcolor={Color.White}>
          {status?.includes('posted_to') && <OrdersListTabs status={status} />}
        </Box>

        {orderView === 'card' && !!pagination?.total_pages && (
          <OrderListCardSortBar
            orders={orders}
            isLoading={!data}
            selectedOrders={selectedOrders}
            sortKey={sort[0]}
            sortOrder={sort[1]}
            onSelectAllOrders={() => setSelectedOrders(orders)}
            onUnselectAllOrders={() => setSelectedOrders([])}
            onSort={handleSort}
          />
        )}

        <OrderActionsProvider
          source="Order List"
          onActionComplete={handleSingleActionComplete}
        >
          <Content onScroll={handleScroll}>
            {pagination?.total_pages === 0 ? (
              <OrdersListPostedEmptyState status={status} />
            ) : orderView === 'card' ? (
              <OrdersListCardView
                orders={orders}
                isLoading={!data || isPreviousData}
                selectedOrders={selectedOrders}
                searchQuery={query}
                toggleSelectOrder={toggleSelectedOrder}
                renderActions={(order: Order) => (
                  <OrderActions order={order} source="orders_list" />
                )}
              />
            ) : (
              <OrderTable
                orders={orders}
                isLoading={!data || isPreviousData}
                searchQuery={query}
                onSort={handleSort}
                sortKey={sort[0]}
                sortOrder={sort[1]}
                selectedOrders={selectedOrders}
                toggleSelectOrder={toggleSelectedOrder}
                onSelectAllOrders={() => setSelectedOrders(orders)}
                onUnselectAllOrders={() => setSelectedOrders([])}
                renderActions={(order) => <OrderListActions order={order} />}
              />
            )}

            {!!pagination?.total_pages && platform === 'mobile' && (
              <OrdersListFooter
                page={pagination.page}
                pageSize={pagination.limit}
                totalPages={pagination.total_pages}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
              />
            )}
          </Content>
        </OrderActionsProvider>

        {!!pagination?.total_pages && platform !== 'mobile' && (
          <OrdersListFooter
            page={pagination.page}
            pageSize={pagination.limit}
            totalPages={pagination.total_pages}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        )}
      </PageLayout>
    </SourceManager>
  );
}
