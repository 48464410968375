import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
  PopoverProps,
  Typography,
} from '@material-ui/core';
import { stringifyDate } from '@superdispatch/dates';
import { FormikDateField, useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { isSuperpayPayment } from 'core/SuperPayUtils';
import { Form, FormikProvider } from 'formik';
import countBy from 'lodash/countBy';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { usePermission } from 'shared/data/UserPermissions';
import { MarkAsDeliveredDTO } from 'shared/dto/ActionsDTO';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { startOfWorkDay } from 'shared/helpers/DateTimeHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import {
  useBulkOrderActionAPI,
  useSingleOrderActionAPI,
} from '../../data/OrderActionAPI';
import { PaymentOnHoldDialog } from './MarkAsPaymentOnHoldDialog';

interface MarkAsDeliveredPopoverProps<T>
  extends Omit<PopoverProps, 'onSubmit'> {
  orders: Order[] | undefined;
  onSubmitSuccess: (response: T) => void;
  onSubmit: (values: MarkAsDeliveredDTO) => Promise<T>;
  onClose: () => void;
}

type Steps =
  | 'confirm'
  | 'adjust-date'
  | 'payment-hold'
  | 'payment-hold-permission';

function MarkAsDeliveredPopover<T>({
  open,
  onSubmit,
  orders = [],
  onClose,
  onSubmitSuccess,
  ...popoverProps
}: MarkAsDeliveredPopoverProps<T>) {
  const isUnifyDialogs = useFeatureToggle('payments.unify.dialogs.access.ui');
  const { user } = useUserState();
  const [step, setStep] = useState<Steps>('adjust-date');
  const { addSnackbar } = useSnackbarStack();
  const isSuperPayEnabled = user?.shipper.is_super_pay_enabled;
  const canManageSuperPay = usePermission('MANAGE_SUPERPAY', 'canExecute');

  const { superPayOrders, onHoldOrderIds } = useMemo(() => {
    const superPayItems: Order[] = [];
    const onHoldIds: string[] = [];

    orders.forEach((order) => {
      if (isSuperpayPayment(order.payment) && !order.has_loads) {
        superPayItems.push(order);
      }

      if (order.payment?.super_pay?.is_on_hold) {
        onHoldIds.push(order.number);
      }
    });
    return { superPayOrders: superPayItems, onHoldOrderIds: onHoldIds };
  }, [orders]);

  const hasSuperPayOrder = superPayOrders.length > 0;
  const isAllSuperPayOnHold = useMemo(
    () =>
      hasSuperPayOrder &&
      superPayOrders.every((order) => order.payment?.super_pay?.is_on_hold),
    [hasSuperPayOrder, superPayOrders],
  );

  const showAdjustedPickupDate = useMemo(
    () =>
      !!orders.length && orders.every((order) => order.status === 'accepted'),

    [orders],
  );

  const isSuperPay = hasSuperPayOrder && isSuperPayEnabled;
  const shouldShowPermissionDialog = isSuperPay && !canManageSuperPay;
  const shouldShowConfirmDialog =
    user?.shipper.super_pay_settings?.superpay_flow !== 'MANUAL' && isSuperPay;
  const shouldInitiateSuperPayForManual =
    user?.shipper.super_pay_settings?.superpay_flow === 'MANUAL' &&
    isSuperPay &&
    canManageSuperPay;

  const initialValues = useMemo<MarkAsDeliveredDTO>(() => {
    const today = stringifyDate(startOfWorkDay(DateTime.local()), {
      format: 'JodaISO',
    });
    return {
      adjusted_pickup_date: showAdjustedPickupDate ? today : null,
      adjusted_delivery_date: today,
      initiate_payment: null,
      custom_on_hold_reason: '',
    };
  }, [showAdjustedPickupDate]);

  const form = useFormikEnhanced<MarkAsDeliveredDTO, T>({
    key: open,
    initialValues,
    onSubmit,
    onSubmitSuccess,
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, { variant: 'error' });
    },
  });

  useEffect(() => {
    setStep(
      shouldShowPermissionDialog
        ? 'payment-hold-permission'
        : shouldShowConfirmDialog
        ? 'confirm'
        : 'adjust-date',
    );
  }, [shouldShowConfirmDialog, shouldShowPermissionDialog]);

  return (
    <>
      {isUnifyDialogs ? (
        <Dialog
          open={open && step === 'confirm'}
          onClose={onClose}
          maxWidth="xs"
          fullWidth={true}
        >
          <DialogTitle disableTypography={true}>
            <Typography variant="h3">Mark as Delivered?</Typography>
          </DialogTitle>

          <DialogContent>
            <Typography>
              Marking the order(s) as Delivered will also{' '}
              <b>Mark as Payment on Hold.</b> You can release the payment
              manually.
            </Typography>
          </DialogContent>

          <DialogActions>
            <Inline space="small" horizontalAlign="right">
              <Button
                variant="neutral"
                disabled={form.isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={form.isSubmitting}
                pending={form.isSubmitting}
                onClick={() => {
                  setStep('adjust-date');
                }}
              >
                Confirm
              </Button>
            </Inline>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={open && step === 'confirm'}
          onClose={form.isSubmitting ? undefined : onClose}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle disableTypography={true}>
            <Typography variant="h3">
              Mark as Delivered and Schedule Payment?
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Stack space="xsmall">
              <Typography variant="body1">To Mark as Delivered</Typography>
              <Stack space="xxsmall">
                <Typography>
                  Select if payment should be scheduled or put on hold.
                </Typography>
                {!!onHoldOrderIds.length && orders.length > 1 && (
                  <Typography>
                    The following orders are already on hold and will remain
                    there if Mark as Payment on Hold is selected:{' '}
                    <TextBox variant="body-semibold">
                      {onHoldOrderIds.join(', ')}
                    </TextBox>
                    .
                  </Typography>
                )}
              </Stack>
              <Typography>
                Carrier will be aware if you put payment on hold.
              </Typography>
            </Stack>
          </DialogContent>

          <Box padding="medium" display="flex">
            <Columns>
              <Column>
                <Button
                  variant="neutral"
                  disabled={form.isSubmitting}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Column>
              <Column width="content">
                <Inline space="small">
                  <Button
                    variant="neutral"
                    disabled={form.isSubmitting}
                    pending={form.isSubmitting}
                    onClick={() => {
                      form.setFieldValue('initiate_payment', false);
                      setStep(
                        isAllSuperPayOnHold ? 'adjust-date' : 'payment-hold',
                      );
                    }}
                  >
                    {isAllSuperPayOnHold
                      ? 'Keep as Payment on Hold'
                      : 'Mark as Payment on Hold'}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={form.isSubmitting}
                    pending={form.isSubmitting}
                    onClick={() => {
                      form.setFieldValue('initiate_payment', true);
                      setStep('adjust-date');
                    }}
                  >
                    Schedule Payment
                  </Button>
                </Inline>
              </Column>
            </Columns>
          </Box>
        </Dialog>
      )}

      <FormikProvider value={form}>
        <Form>
          <PaymentOnHoldDialog
            open={open && step === 'payment-hold'}
            onClose={onClose}
            onSend={() => {
              setStep('adjust-date');
            }}
          />

          <PaymentOnHoldSuperPayPermissionDialog
            open={open && step === 'payment-hold-permission'}
            isSubmitting={form.isSubmitting}
            isAllOrdersOnHold={isAllSuperPayOnHold}
            onClose={onClose}
            onSend={() => {
              setStep('adjust-date');
            }}
          />

          <Popover
            {...popoverProps}
            open={open && step === 'adjust-date'}
            onClose={form.isSubmitting ? undefined : onClose}
          >
            <Box padding="small">
              <Stack space="small" align="right">
                <Inline space="small">
                  {showAdjustedPickupDate && (
                    <FormikDateField
                      label="Adjusted Pickup Date"
                      name="adjusted_pickup_date"
                    />
                  )}
                  <FormikDateField
                    label="Adjusted Delivery Date"
                    name="adjusted_delivery_date"
                  />
                </Inline>

                <Button
                  variant="primary"
                  pending={form.isSubmitting}
                  onClick={() => {
                    if (shouldInitiateSuperPayForManual) {
                      form.setFieldValue('initiate_payment', true);
                    }
                    void form.submitForm();
                  }}
                >
                  Done
                </Button>
              </Stack>
            </Box>
          </Popover>
        </Form>
      </FormikProvider>
    </>
  );
}

interface BulkMarkAsDeliveredPopoverProps
  extends Omit<PopoverProps, 'onSubmit' | 'open'> {
  orders: Order[] | undefined;
  onSubmitSuccess: () => void;
  onClose: () => void;
}

export function BulkMarkAsDeliveredPopover({
  orders,
  onClose,
  anchorEl,
  onSubmitSuccess,
  ...popoverProps
}: BulkMarkAsDeliveredPopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const { bulkMarkAsDelivered } = useBulkOrderActionAPI();

  return (
    <MarkAsDeliveredPopover
      {...popoverProps}
      open={!!orders && !!anchorEl}
      anchorEl={anchorEl}
      orders={orders}
      onClose={onClose}
      onSubmit={(values) => {
        if (orders) {
          const ids = orders.map((x) => x.id);
          return bulkMarkAsDelivered(ids, values);
        }

        return Promise.reject(new Error('Order not found'));
      }}
      onSubmitSuccess={() => {
        if (orders) {
          addSnackbar(
            `${orders.length} ${formatPlural(
              orders.length,
              'order',
              'orders',
            )} marked as delivered`,
            { variant: 'success' },
          );
          trackEventLegacy('Bulk Mark as Delivered', {
            count: orders.length,
            orders: countBy(orders, 'status'),
          });
          onSubmitSuccess();
        }
      }}
    />
  );
}

interface SingleMarkAsDeliveredPopoverProps
  extends Omit<PopoverProps, 'onSubmit' | 'open'> {
  order?: Order;
  onSubmitSuccess: (order: Order) => void;
  onClose: () => void;
}

export function SingleMarkAsDeliveredPopover({
  order,
  onClose,
  anchorEl,
  onSubmitSuccess,
  ...popoverProps
}: SingleMarkAsDeliveredPopoverProps) {
  const { addSnackbar } = useSnackbarStack();
  const { markAsDelivered } = useSingleOrderActionAPI();

  return (
    <MarkAsDeliveredPopover
      {...popoverProps}
      open={!!order && !!anchorEl}
      anchorEl={anchorEl}
      orders={order && [order]}
      onClose={onClose}
      onSubmit={(values) => {
        if (order) {
          return markAsDelivered(order.id, values);
        }

        return Promise.reject(new Error('Order not found'));
      }}
      onSubmitSuccess={(updatedOrder: Order) => {
        addSnackbar('Status changed successfully', { variant: 'success' });
        trackEventLegacy('Marked as Delivered');
        onSubmitSuccess(updatedOrder);
      }}
    />
  );
}

interface PaymentOnHoldSuperPayPermissionProps {
  open: boolean;
  onClose: () => void;
  onSend: () => void;
  isSubmitting?: boolean;
  isAllOrdersOnHold: boolean;
}

function PaymentOnHoldSuperPayPermissionDialog({
  open,
  onClose,
  onSend,
  isSubmitting,
  isAllOrdersOnHold,
}: PaymentOnHoldSuperPayPermissionProps) {
  return (
    <Dialog
      open={open}
      onClose={isSubmitting ? undefined : onClose}
      maxWidth="xs"
      fullWidth={true}
    >
      <Box padding="medium">
        <Stack space="small">
          <Typography variant="h3">
            Mark as Delivered and Payment on Hold?
          </Typography>
          <Stack space="large">
            <Typography>
              To proceed without a hold, please ensure you have the &quot;Manage
              SuperPay&quot; permission enabled.
            </Typography>

            <Inline space="small" horizontalAlign="right">
              <Button
                variant="neutral"
                disabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                pending={isSubmitting}
                onClick={onSend}
              >
                {isAllOrdersOnHold
                  ? 'Keep as Payment on Hold'
                  : 'Mark as Payment on Hold'}
              </Button>
            </Inline>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
