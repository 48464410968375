import { Typography } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Alert, Box, Button } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { usePermission } from 'shared/data/UserPermissions';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { usePaymentLogger } from 'superpay/helpers/PaymentLogger';
import { SuperPayPageLayout } from 'superpay/SuperPayPageLayout';
import { ROUTES_SUPERPAY } from 'superpay/SuperPayRoute';
import { useVerificationApplication } from '../../shipper-profile/data/VerificationApplicationAPI';
import bankVerifiedIllustration from '../assets/bank_verified_illustration.png';
import confirmDeliveryIllustration from '../assets/confirm_delivery_illustration_new.png';
import digitalDeliveryVerificationIllustration from '../assets/digital_delivery_verification_illustration.png';
import paymentControlIllustration from '../assets/payment_control_illustration.png';
import paymentReceivedIllustration from '../assets/payment_received_illustration.png';
import setupSuperPayIllustration from '../assets/setup_superpay_illustration.png';
import streamlineYourPaymentsIllustration from '../assets/streamline_your_payments.png';
import superPayCarriersIllustration from '../assets/superpay_carriers_illustration.png';
import superPayGradientTitle from '../assets/superpay_gradient_title_2x.png';
import {
  BannerContainer,
  GradientCardContainer,
  PromoCard,
  PromoCardTitle,
  PromoCardWide,
  PromoFlexibleWorkflows,
} from './SuperPayPromoComponents';
import { SuperPayRequestedPromoBanner } from './SuperPayRequestedPromoBanner';
import { SuperPayStatistics } from './SuperPayStatistics';

export function SuperPayPromoPage() {
  const canManageSuperPay = usePermission('MANAGE_SUPERPAY', 'canExecute');
  const { logPaymentInfo } = usePaymentLogger();
  const { user } = useUserState();
  const { data: verificationData } = useVerificationApplication();
  const isUnverifiedShipper = verificationData?.status !== 'VERIFIED';
  const isCarrierRequestsSuperPay = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );

  useEffect(() => {
    trackEvent('Shipper Opened SuperPay Promo Page');
    logPaymentInfo('Opened SuperPay Promo Page', 'SuperPayPromoPage');
  }, [logPaymentInfo]);

  return (
    <SuperPayPageLayout>
      <BannerContainer aria-label="superpay promo banner">
        <Stack space="xxlarge">
          <GradientCardContainer>
            <Box padding="small">
              <Stack space={['medium', 'small', 'small']} align="center">
                <Stack align="center" space="large">
                  <Box
                    width={['270px', '324px']}
                    marginTop={['xsmall', 'xsmall', 'xxlarge']}
                  >
                    <img
                      src={superPayGradientTitle}
                      width="100%"
                      alt="SuperPay"
                    />
                  </Box>

                  {isCarrierRequestsSuperPay &&
                    user?.shipper.super_pay_settings
                      ?.superpay_requested_banner_available && (
                      <SuperPayRequestedPromoBanner />
                    )}

                  {canManageSuperPay && !isUnverifiedShipper && (
                    <Stack align="center" space={['xsmall', 'small', 'small']}>
                      <Box width="293px">
                        <img
                          src={streamlineYourPaymentsIllustration}
                          width="100%"
                          alt="Streamline Your Payments"
                        />
                      </Box>
                      <SetupSuperPayButton source="Top Button" />
                    </Stack>
                  )}
                </Stack>
                <Box marginTop={['medium', 'medium', 'large']} width="100%">
                  <Stack space="medium" align="center" aria-label="Statics">
                    <PromoCardTitle>Save Time & Money</PromoCardTitle>
                    <SuperPayStatistics />
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </GradientCardContainer>

          <Box padding="small">
            <Stack space="medium" align="center">
              <PromoCardTitle>
                Getting Started is as Simple as...
              </PromoCardTitle>
              <Columns space="xsmall" collapseBelow="tablet">
                <Column width="1/3">
                  <PromoCard
                    backgroundColor="Silver200"
                    imgSrc={setupSuperPayIllustration}
                    title="Setup SuperPay"
                    subtitle="Secure account setup with verification in as little as 2 business days."
                  />
                </Column>
                <Column width="1/3">
                  <PromoCard
                    backgroundColor="Silver200"
                    imgSrc={confirmDeliveryIllustration}
                    title="Confirm Delivery"
                    subtitle="Save time and streamline payment with Digital Delivery Verification."
                  />
                </Column>
                <Column width="1/3">
                  <PromoCard
                    backgroundColor="Silver200"
                    imgSrc={superPayCarriersIllustration}
                    title="SuperPay Carriers"
                    subtitle="Simplify accounts payable with integrated payments."
                  />
                </Column>
              </Columns>
            </Stack>
          </Box>

          <Stack space="small">
            <Box padding="small">
              <Stack space="small">
                <Stack space="medium" align="center">
                  <PromoCardTitle>
                    Payments Made Easy. All on One Platform
                  </PromoCardTitle>
                  <PromoCardWide
                    title="Effortless Carrier Account Setup"
                    subtitle="Remove the hassle of setting up carriers and payments for each delivery. SuperPay takes care of carrier payment setup by verifying and securely storing payment data for you."
                    imgSrc={bankVerifiedIllustration}
                  />
                </Stack>
                <Columns space="xsmall" collapseBelow="tablet">
                  <Column width="1/2">
                    <PromoCard
                      minHeight="348px"
                      maxImgWidth="240px"
                      backgroundColor="Silver200"
                      imgSrc={paymentReceivedIllustration}
                      title="Real-time Payment Visibility "
                      subtitle="Say goodbye to phone tag status updates, lost paper checks, and accidental double payments."
                    />
                  </Column>
                  <Column width="1/2">
                    <PromoCard
                      minHeight="348px"
                      maxImgWidth="240px"
                      backgroundColor="Blue50"
                      imgSrc={digitalDeliveryVerificationIllustration}
                      title="Confidence in Delivery"
                      subtitle="Digital Delivery Verification uses GPS tracking, vehicle photos, and proof of signature to verify delivery before payments are made."
                    />
                  </Column>
                </Columns>
              </Stack>
            </Box>
            {canManageSuperPay && !isUnverifiedShipper && (
              <Inline verticalAlign="center" horizontalAlign="center">
                <SetupSuperPayButton source="Center Button" />
              </Inline>
            )}
          </Stack>

          <Stack space="small">
            <Box padding="small">
              <Stack space="small">
                <Stack space="medium" align="center">
                  <Inline space="xxsmall" horizontalAlign="center">
                    <Box width="108px">
                      <img
                        src={superPayGradientTitle}
                        width="100%"
                        alt="SuperPay"
                      />
                    </Box>
                    <PromoCardTitle>Your Way</PromoCardTitle>
                  </Inline>
                  <PromoCardWide
                    title="Total Payment Control"
                    subtitle="Automate, schedule, or customize your payment processing to ensure your carriers get paid on time, every time."
                    imgSrc={paymentControlIllustration}
                  />
                </Stack>
                <PromoFlexibleWorkflows />
              </Stack>
            </Box>
            <Inline verticalAlign="center" horizontalAlign="center">
              {isUnverifiedShipper ? (
                <UnverifiedShipperAlert />
              ) : canManageSuperPay ? (
                <Stack align="center">
                  <SetupSuperPayButton source="Footer Button" />
                  <Typography color="textSecondary">
                    Simple transparent pricing: $1 per transaction.
                  </Typography>
                </Stack>
              ) : (
                <NoSuperPayPermissionAlert />
              )}
            </Inline>
          </Stack>
        </Stack>
      </BannerContainer>
    </SuperPayPageLayout>
  );
}

interface ButtonProps {
  source: 'Top Button' | 'Center Button' | 'Footer Button';
}

function SetupSuperPayButton({ source }: ButtonProps) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { logPaymentInfo } = usePaymentLogger();
  return (
    <Button
      size="large"
      aria-label="setup superpay"
      onClick={() => {
        trackEvent('Shipper Clicked Set Up SuperPay', {
          utm_medium: 'SuperPay Promo Page',
          utm_content: source,
        });
        logPaymentInfo('Clicked Set Up SuperPay', 'SuperPayPromoPage');
        navigate({
          pathname: ROUTES_SUPERPAY.businessDetails,
          search,
        });
      }}
    >
      Set Up SuperPay
    </Button>
  );
}

function NoSuperPayPermissionAlert() {
  return (
    <Box
      maxWidth="472px"
      minWidth={['auto', 'auto', '472px']}
      paddingLeft={['small', 'small', 'none']}
      paddingRight={['small', 'small', 'none']}
    >
      <Alert severity="info" icon={<Settings />}>
        <Typography>
          Admin or Accounting roles can set up SuperPay, contact your manager
          for assistance.
        </Typography>
      </Alert>
    </Box>
  );
}

function UnverifiedShipperAlert() {
  return (
    <Box
      maxWidth="472px"
      minWidth={['auto', 'auto', '472px']}
      paddingLeft={['small', 'small', 'none']}
      paddingRight={['small', 'small', 'none']}
    >
      <Alert severity="info">
        <Box position="relative" top="2px">
          <Typography>
            SuperPay is not available to Unverified Shippers.
          </Typography>
        </Box>
      </Alert>
    </Box>
  );
}
