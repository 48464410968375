import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom-v5-compat';
import Order from 'shared/types/order';

interface SuperPaySuspendDialogProps {
  title: string;
  onClose: () => void;
  order?: Order;
}

export function SuperPaySuspendDialog({
  title,
  onClose,
  order,
}: SuperPaySuspendDialogProps) {
  const navigate = useNavigate();
  const guid = order?.guid;

  return (
    <Dialog open={!!order} onClose={onClose} maxWidth="xs">
      <DialogTitle disableTypography={true}>
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>

      <DialogContent>
        SuperPay not available. Change payment method to proceed.
      </DialogContent>

      <DialogActions>
        <Inline horizontalAlign="right">
          <Button onClick={onClose} variant="neutral">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (guid) {
                navigate(`/orders/edit/${guid}?target=carrier-payment-method`);
              } else {
                onClose();
              }
            }}
          >
            Change Payment Method
          </Button>
        </Inline>
      </DialogActions>
    </Dialog>
  );
}
