import { useDefaultTimeZone } from '@superdispatch/dates';
import { ForgotPasswordPage } from 'auth/ForgotPasswordPage';
import { ResetPasswordPage } from 'auth/ResetPasswordPage';
import { VerifyAccountPage } from 'auth/VerifyAccountPage';
import { CustomersPage } from 'customers/CustomersPage';
import { ManageFieldsPage } from 'manage-fields/ManageFieldsPage';
import { ManageUsersPage } from 'manage-users/ManageUsersPage';
import { DashboardOrdersPage } from 'orders/dashboard/DashboardOrdersPage';
import { lazy, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom-v5-compat';
import { ReportsPage } from 'reports/ReportsPage';
import { OnboardingProvider } from 'shared/context/OnboardingContext';
import { useUserState } from 'shared/data/AppUserState';
import { isInternalUser } from 'shared/data/AppUtils';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useOnboardingState } from 'shared/data/OnboardingState';
import { Paywall } from 'shared/errors/paywall/Paywall';
import { useHasPaywall } from 'shared/errors/paywall/PaywallEvents';
import { browserFingerprint } from 'shared/helpers/BrowserFingerprint';
import { identifyErrorTrackerUser } from 'shared/helpers/MonitoringService';
import { VerificationApplicationContainer } from 'shipper-profile/verification-application/VerificationApplicationContainer';
import { SubscriptionDetailsPage } from 'subscription-details/SubscriptionDetailsPage';
import { SubscriptionDetailsPageLegacy } from 'subscription-details/SubscriptionDetailsPageLegacy';
import { SubscriptionPlansContainer } from 'subscription-plans/SubscriptionPlansContainer';
import { SuperPayContainer } from 'superpay/SuperPayContainer';
import { AppBlankRoute, AppProtectedRoute, AppRoute } from './AppRouter';
import { LoginPage } from './auth/LoginPage';
import { BillingPaymentForm } from './BillingPaymentForm';
import { ComplianceFormDialog } from './ComplianceFormDialog';
import { GeneralSettingsPage } from './general-settings/GeneralSettingsPage';
import { SuperLoadboardSettingsPage } from './loadboard-settings/SuperLoadboardSettingsPage';
import { LoadingContainer } from './LoadingContainer';
import { ManageCarriersContainer } from './manage-carriers/ManageCarriersContainer';
import { NotificationSettingsPage } from './notification-settings/NotificationSettingsPage';
import { OrdersContainer } from './orders/OrdersContainer';
import { enableTokenRefresh } from './shared/api/API';
import { useNotificationHandler } from './shared/data/notifications/NotificationHelpers';
import {
  identifyUser,
  trackEventLegacy,
} from './shared/helpers/AnalyticsHelpers';
import {
  hideIntercomButton,
  showIntercomButton,
  updateIntercom,
} from './shared/helpers/IntercomHelpers';
import { stringifySearchQuery, useQuery } from './shared/helpers/RouteHelpers';
import { ShipperProfilePage } from './shipper-profile/ShipperProfilePage';
import { TerminalsPage } from './terminals/TerminalsPage';

const DashboardPageLazy = lazy(() => import('./dashboard/DashboardPage'));

const OnboardingPageLegacyLazy = lazy(
  () => import('onboarding/OnboardingPageLegacy'),
);

const OnboardingPageLazy = lazy(() => import('onboarding/OnboardingPage'));

export function App() {
  const { user, authState } = useUserState();

  const hasPaywall = useHasPaywall();

  useDefaultTimeZone(user?.shipper.zone_offset_minutes ?? 'local');

  useNotificationHandler();

  useEffect(() => {
    if (authState === 'pending') {
      return;
    }

    if (user) {
      identifyUser(user, () => {
        // update intercom surveys, tours etc.
        updateIntercom();
      });
      identifyErrorTrackerUser({
        id: user.guid,
        shipperGuid: user.shipper.guid,
        shipperName: user.shipper.name,
        browserFingerprint,
      });

      if (user.shipper.shipper_type === 'CUSTOMER' || isInternalUser(user)) {
        hideIntercomButton();
      } else {
        showIntercomButton();
      }
    } else {
      identifyUser();
    }

    trackEventLegacy('Started Session');
  }, [user]);

  if (authState === 'pending') {
    return <LoadingContainer />;
  }

  if (authState === 'unauthorized') {
    return <UnauthenticatedRoutes />;
  }

  return (
    <OnboardingProvider key={user?.guid}>
      <ComplianceFormDialog />
      {hasPaywall ? <AuthenticatedPaywallRoutes /> : <AuthenticatedAppRoutes />}
    </OnboardingProvider>
  );
}

function UnauthenticatedRoutes() {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/signin/verify" element={<VerifyAccountPage />} />
      <Route path="/signin" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route
        path="*"
        element={
          <Navigate
            to={{
              pathname: '/signin',
              search: stringifySearchQuery({
                redirect_url: `${location.pathname}${decodeURIComponent(
                  location.search,
                )}`,
              }),
            }}
          />
        }
      />
    </Routes>
  );
}

function AuthenticatedPaywallRoutes() {
  return (
    <Routes>
      <Route
        path="/subscription-plans/*"
        element={
          <AppBlankRoute>
            <SubscriptionPlansContainer />
          </AppBlankRoute>
        }
      />
      <Route path="*" element={<Paywall />} />
    </Routes>
  );
}

function AuthenticatedAppRoutes() {
  const { showOnboarding, isNewOnboardingAvailable } = useOnboardingState();
  const isSubscriptionEnabled = useFeatureToggle(
    'update-credit-card-info.enabled',
  );
  const isSubscriptionDetailsV2Enabled = useFeatureToggle(
    'subscription-details-v2.ui',
  );
  const isTokenRefreshEnabled = useFeatureToggle('stms.refresh-token.enabled');
  const [{ redirect_url }] = useQuery();
  const { user, isAdmin, isSuperUser } = useUserState();
  const showSubscription =
    (isAdmin || isSuperUser) &&
    user?.shipper.is_self_serve &&
    isSubscriptionEnabled;

  const showPlans = isSubscriptionEnabled && (isAdmin || isSuperUser);

  useEffect(() => {
    if (!isTokenRefreshEnabled) {
      return;
    }

    enableTokenRefresh();
  }, [isTokenRefreshEnabled]);

  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <AppProtectedRoute>
            <DashboardPageLazy />
          </AppProtectedRoute>
        }
      />

      <Route
        path="/orders/*"
        element={
          <AppRoute>
            <OrdersContainer />
          </AppRoute>
        }
      />

      <Route
        path="/overview/orders/:type"
        element={
          <AppRoute>
            <DashboardOrdersPage />
          </AppRoute>
        }
      />

      <Route
        path="/customers/*"
        element={
          <AppProtectedRoute>
            <CustomersPage />
          </AppProtectedRoute>
        }
      />

      <Route
        path="/terminals/*"
        element={
          <AppRoute>
            <TerminalsPage />
          </AppRoute>
        }
      />

      <Route
        path="/reports/:id?"
        element={
          <AppProtectedRoute>
            <ReportsPage />
          </AppProtectedRoute>
        }
      />

      {showOnboarding && (
        <Route
          path="/getting-started"
          element={
            <AppRoute>
              {isNewOnboardingAvailable ? (
                <OnboardingPageLazy />
              ) : (
                <OnboardingPageLegacyLazy />
              )}
            </AppRoute>
          }
        />
      )}

      {showPlans && (
        <Route
          path="/subscription-plans/*"
          element={
            <AppRoute>
              <SubscriptionPlansContainer />
            </AppRoute>
          }
        />
      )}

      <Route
        path="/manage-carriers/*"
        element={
          <AppRoute>
            <ManageCarriersContainer />
          </AppRoute>
        }
      />

      <Route
        path="/profile/*"
        element={
          <AppRoute>
            <ShipperProfilePage />
          </AppRoute>
        }
      />

      <Route
        path="/verification-application/*"
        element={
          <AppBlankRoute>
            <VerificationApplicationContainer />
          </AppBlankRoute>
        }
      />

      <Route
        path="/notification-settings"
        element={
          <AppRoute>
            <NotificationSettingsPage />
          </AppRoute>
        }
      />

      <Route
        path="/superpay/*"
        element={
          <AppRoute>
            <SuperPayContainer />
          </AppRoute>
        }
      />

      {showSubscription && (
        <Route
          path="/subscription"
          element={
            <AppRoute>
              {isSubscriptionDetailsV2Enabled ? (
                <SubscriptionDetailsPage />
              ) : (
                <SubscriptionDetailsPageLegacy />
              )}
            </AppRoute>
          }
        />
      )}

      <Route
        path="/users/*"
        element={
          <AppRoute>
            <ManageUsersPage />
          </AppRoute>
        }
      />

      <Route
        path="/manage-fields"
        element={
          <AppProtectedRoute>
            <ManageFieldsPage />
          </AppProtectedRoute>
        }
      />

      <Route
        path="/general-settings"
        element={
          <AppRoute>
            <GeneralSettingsPage />
          </AppRoute>
        }
      />

      <Route
        path="/super-loadboard-settings"
        element={
          <AppRoute>
            <SuperLoadboardSettingsPage />
          </AppRoute>
        }
      />

      <Route path="/payment-form" element={<BillingPaymentForm />} />

      <Route
        path="*"
        element={
          <Navigate
            to={typeof redirect_url === 'string' ? redirect_url : '/orders'}
          />
        }
      />
    </Routes>
  );
}
