import { DateConfigProvider } from '@superdispatch/dates';
import { FormsProvider } from '@superdispatch/forms';
import { dequal } from 'dequal';
import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { AuthProvider } from 'shared/data/AuthProvider';
import { defaultFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { AppErrorBoundary } from './shared/errors/AppErrorBoundary';
import { GoogleMapsProvider } from './shared/google-maps/GoogleMapsContext';
import { AppTheme } from './shared/theme/AppTheme';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryOnMount: false,
      isDataEqual: dequal,
      staleTime: 10 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  },
});

export function RootProvider({ children }: { children: ReactNode }) {
  return (
    <AppErrorBoundary>
      <AppTheme>
        <DateConfigProvider format="JodaISO">
          <BrowserRouter>
            <CompatRouter>
              <GoogleMapsProvider>
                <FormsProvider getFormErrors={defaultFormErrorsGetter}>
                  <QueryClientProvider client={queryClient}>
                    {!('Cypress' in window) &&
                      import.meta.env.MODE === 'development' && (
                        <ReactQueryDevtools initialIsOpen={false} />
                      )}
                    <AuthProvider>{children}</AuthProvider>
                  </QueryClientProvider>
                </FormsProvider>
              </GoogleMapsProvider>
            </CompatRouter>
          </BrowserRouter>
        </DateConfigProvider>
      </AppTheme>
    </AppErrorBoundary>
  );
}
