import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom-v5-compat';
import { VerificationApplicationPage } from './VerificationApplicationPage';
import { VerificationUnderwayPage } from './VerificationUnderwayPage';
import { VerifiedApplicationInfoPage } from './VerifiedApplicationInfoPage';

export function VerificationApplicationContainer() {
  const location = useLocation();
  return (
    <Routes>
      <Route path="info" element={<VerifiedApplicationInfoPage />} />
      <Route path="underway" element={<VerificationUnderwayPage />} />
      <Route path="steps/*" element={<VerificationApplicationPage />} />
      <Route
        path="*"
        element={
          <Navigate to={`/verification-application/info${location.search}`} />
        }
      />
    </Routes>
  );
}
