import { useFormikEnhanced } from '@superdispatch/forms';
import { Button, useSnackbarStack } from '@superdispatch/ui';
import { useState } from 'react';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import { ConfirmPopover } from 'shared/ui/ConfirmationPopup';
import { useTerminalsNavigation } from 'terminals/TerminalsRouteHelpers';
import { useTerminalsAPI } from '../data/TerminalsAPI';

interface Props {
  terminal?: TerminalDTO;
}

export function TerminalDeleteConfirmation({ terminal }: Props) {
  const [anchor, setAnchor] = useState<HTMLElement>();
  const { addSnackbar } = useSnackbarStack();
  const { deleteTerminal } = useTerminalsAPI();
  const { navigateToTerminals } = useTerminalsNavigation();

  const deleteForm = useFormikEnhanced({
    initialValues: {},
    onSubmit() {
      if (!terminal?.id) {
        return Promise.reject(new Error('No terminal selected'));
      }

      return deleteTerminal(terminal.id);
    },
    onSubmitSuccess() {
      setAnchor(undefined);
      addSnackbar('Terminal deleted');
      navigateToTerminals();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        isLoading={deleteForm.isSubmitting}
        onClick={(event) => {
          setAnchor(event.currentTarget);
        }}
      >
        Delete
      </Button>

      <ConfirmPopover
        open={!!anchor}
        anchorEl={anchor}
        title="Delete this terminal?"
        cancelButtonProps={{
          children: 'No',
          onClick: () => setAnchor(undefined),
        }}
        confirmButtonProps={{
          onClick: () => deleteForm.handleSubmit(),
          children: 'Yes, Delete',
        }}
      />
    </>
  );
}
