import { Typography } from '@material-ui/core';
import { CheckboxField, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { SuperPayLogo } from 'shared/icons/SuperPayLogo';
import styled from 'styled-components';

const PointerSuperPayLogo = styled(SuperPayLogo)`
  cursor: pointer;
`;

type Source =
  | 'Carrier Profile'
  | 'Carrier Search'
  | 'Carrier Requests Drawer'
  | 'Direct Offer Suggested'
  | 'Single Send Offer Drawer'
  | 'Direct Offer Previously Dispatched';

interface CarrierAcceptsSuperPayProps {
  source: Source;
  compact?: boolean;
}

export function CarrierAcceptsSuperPay({
  source,
  compact,
}: CarrierAcceptsSuperPayProps) {
  const navigate = useNavigate();

  return (
    <Inline space="xxsmall" verticalAlign="center">
      <Typography
        color="textSecondary"
        variant={compact ? 'caption' : undefined}
      >
        Accepts
      </Typography>
      <PointerSuperPayLogo
        width={compact ? '60px' : undefined}
        onClick={() => {
          navigate({
            pathname: '/superpay',
            search: `?utm_medium=${source}&utm_content=Accepts SuperPay`,
          });
        }}
      />
    </Inline>
  );
}

interface AcceptsSuperPayChangeTermsProps {
  source: Source;
  checked: boolean;
  disabled: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export function AcceptsSuperPayChangeTerms({
  source,
  checked,
  disabled,
  onChange,
}: AcceptsSuperPayChangeTermsProps) {
  const navigate = useNavigate();

  return (
    <Box
      borderWidth="small"
      borderRadius="small"
      borderColor="Silver500"
      padding="xsmall"
    >
      <Stack space="none">
        <Inline space="xxsmall" verticalAlign="center">
          <Box color="Dark200" fontSize="12px">
            Accepts
          </Box>
          <PointerSuperPayLogo
            width="60px"
            onClick={() => {
              navigate({
                pathname: '/superpay',
                search: `?utm_medium=${source}`,
              });
            }}
          />
        </Inline>
        <CheckboxField
          label={
            <Inline verticalAlign="center" space="xxsmall">
              <Inline verticalAlign="center" noWrap={true}>
                Change to
                <PointerSuperPayLogo
                  onClick={() => {
                    navigate({
                      pathname: '/superpay',
                      search: `?utm_medium=${source}`,
                    });
                  }}
                />
              </Inline>
              · 1-3 Business Days
            </Inline>
          }
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
      </Stack>
    </Box>
  );
}
