import { Route, Routes } from 'react-router-dom-v5-compat';
import { SubscriptionPlansExpressPage } from './express-subscription-plan/SubscriptionPlansExpressPage';
import { SubscriptionPlansProPage } from './pro-subscription-plan/SubscriptionPlansProPage';
import { SubscriptionPlansIndexPage } from './SubscriptionPlansIndexPage';

export function SubscriptionPlansContainer() {
  return (
    <Routes>
      <Route
        path="shipper-express"
        element={<SubscriptionPlansExpressPage />}
      />
      <Route path="shipper-pro" element={<SubscriptionPlansProPage />} />
      <Route path="/" element={<SubscriptionPlansIndexPage />} />
    </Routes>
  );
}
