import { Link, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { AccountVerifiedIcon } from 'shared/icons/AccountVerifiedIcon';
import { RequirementItem } from 'shared/ui/RequirementItem';
import styled from 'styled-components';
import brokersIllustration from '../assets/brokers.png';
import dealersIllustration from '../assets/dealers.png';
import otherShippersIllustration from '../assets/other-shippers.png';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import {
  VerificationApplicationLayout,
  VerificationApplicationWrapper,
} from './VerificationApplicationLayout';

const MainContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  padding-bottom: 16px;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const LetsGetYouVerifiedBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  max-width: 480px;
  padding: 40px 40px 40px 16px;

  @media (max-width: 1080px) {
    margin: 0 auto;
    height: 380px;
  }

  @media (max-width: 790px) {
    height: auto;
    padding: 16px;
  }

  & > div:first-child {
    margin: auto 0;
  }
`;

const Verified = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  padding-left: 16px;
  padding-right: 5px;
  border-radius: 58px;

  background-color: ${Color.Green50};
  color: ${Color.Green300};
  border: 2px solid ${Color.Green75};
  font-size: 32px;
  line-height: 40px;
`;

const VerifiedIcon = styled(AccountVerifiedIcon)`
  background-color: ${Color.White};
  border-radius: 50%;
`;

const RequirementsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
`;

const Requirements = styled.div`
  display: flex;
  flex: 0 1 auto;
  gap: 16px;
  width: 1080px;

  @media (max-width: 1610px) {
    width: 100%;
  }

  @media (max-width: 790px) {
    flex-direction: column;
    align-items: center;
    flex: 0 1 auto;
  }
`;

const RequirementsBlock = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;

  background-color: ${Color.Silver200};
  border-radius: 8px;
  padding: 24px;

  & > div {
    min-height: 300px;
  }

  @media (max-width: 790px) {
    width: 480px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Illustration = styled.img`
  width: 140px;
`;

export function VerifiedApplicationInfoPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const isInternationalShippersEnabled = useFeatureToggle(
    'verification-application.international-shippers.enabled.ui',
  );

  const backURL = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('back_url') || '/profile';
  }, [location]);

  useEffect(() => {
    trackVerificationApplicationEvent({
      name: 'Shipper Opened Verification Application',
    });
  }, []);

  return (
    <VerificationApplicationLayout
      onBackButton={() => {
        trackVerificationApplicationEvent({
          name: 'Shipper Closed Verification Application',
          step: 'Verification Info',
        });
        navigate(backURL, { replace: true });
      }}
    >
      <VerificationApplicationWrapper
        showBackButton={false}
        saveButtonTitle="Get Started"
        onSubmit={() => {
          trackVerificationApplicationEvent({
            name: 'Shipper Continued to Your Company Step',
          });
          navigate(`/verification-application/steps/1${location.search}`);
        }}
      >
        <MainContainer>
          <LetsGetYouVerifiedBlock>
            <Stack>
              <Inline verticalAlign="center" space="xsmall">
                <Typography variant="h1">Let’s get you</Typography>
                <Verified>
                  <Box>Verified</Box>
                  <VerifiedIcon htmlColor={Color.Green300} fontSize="large" />
                </Verified>
              </Inline>
              <Typography variant="h4" color="textSecondary">
                Verifying your shipper account is easy and guarantees only
                authorized shippers are on the platform.
              </Typography>
            </Stack>
            {isInternationalShippersEnabled && (
              <Inline noWrap={true} verticalAlign="center" space="xsmall">
                <Info fontSize="medium" htmlColor={Color.Green300} />
                <Stack space="none">
                  <Typography color="textSecondary">
                    Doing business from outside of the U.S.?
                  </Typography>
                  <Link
                    color="textSecondary"
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      trackVerificationApplicationEvent({
                        name: 'Shipper Clicked on "Doing Business From Outside Of The US" Link',
                      });
                    }}
                  >
                    Contact our Compliance Team
                  </Link>
                </Stack>
              </Inline>
            )}
          </LetsGetYouVerifiedBlock>
          <RequirementsWrapper>
            <Requirements>
              <RequirementsBlock>
                <Stack space="medium" align="center">
                  <Illustration src={brokersIllustration} alt="Brokers" />
                  <Stack space="small">
                    <Typography variant="h4">Required for Brokers:</Typography>
                    <Stack>
                      <RequirementItem
                        text="Current W-9"
                        textColor="textSecondary"
                      />
                      <RequirementItem
                        text="USDOT Number"
                        textColor="textSecondary"
                      />
                      <RequirementItem
                        text="Certificate of Insurance"
                        textColor="textSecondary"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </RequirementsBlock>
              <RequirementsBlock>
                <Stack space="medium" align="center">
                  <Illustration src={dealersIllustration} alt="Brokers" />
                  <Stack space="small">
                    <Typography variant="h4">Required for Dealers:</Typography>
                    <Stack>
                      <RequirementItem
                        text="Current W-9"
                        textColor="textSecondary"
                      />
                      <RequirementItem
                        text="Dealer License"
                        textColor="textSecondary"
                      />
                      <RequirementItem
                        text="Certificate of Insurance"
                        textColor="textSecondary"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </RequirementsBlock>
              <RequirementsBlock>
                <Stack space="medium" align="center">
                  <Illustration src={otherShippersIllustration} alt="Brokers" />
                  <Stack space="small">
                    <Typography variant="h4">
                      For All Other Shippers:
                    </Typography>
                    <Stack>
                      <RequirementItem
                        text="Current W-9"
                        textColor="textSecondary"
                      />
                      <RequirementItem
                        text="Certificate of Insurance"
                        textColor="textSecondary"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </RequirementsBlock>
            </Requirements>
          </RequirementsWrapper>
        </MainContainer>
      </VerificationApplicationWrapper>
    </VerificationApplicationLayout>
  );
}
