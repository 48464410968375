import { CircularProgress } from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { renderChildren } from '@superdispatch/ui';
import { ReactNode, Suspense } from 'react';
import { Navigate } from 'react-router-dom-v5-compat';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { AppNavbar } from 'shared/layout/AppNavbar';

export function AppRoute({ children }: { children: ReactNode }) {
  return (
    <AppNavbar>
      <AppBlankRoute>{children}</AppBlankRoute>
    </AppNavbar>
  );
}

export function AppBlankRoute({ children }: { children: ReactNode }) {
  const { authState } = useUserState();

  if (authState === 'pending') {
    return (
      <Flex flex={1} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Flex>
    );
  }

  return (
    <Suspense
      fallback={
        <Flex flex={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Flex>
      }
    >
      {children}
    </Suspense>
  );
}

export function ProtectedRoute({ children }: { children: ReactNode }) {
  const { isBasicTier } = useProductTiers();

  if (isBasicTier) {
    return <Navigate to="/orders" />;
  }

  return renderChildren(children);
}

export function AppProtectedRoute({ children }: { children: ReactNode }) {
  const { isBasicTier } = useProductTiers();

  if (isBasicTier) {
    return <Navigate to="/orders" />;
  }

  return <AppRoute>{children}</AppRoute>;
}
