import { Box, Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useCanExecute } from 'shared/data/UserPermissions';
import { useShipperProfileAPI } from '../shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../shipper-profile/data/ShipperProfileDTO';
import {
  SectionHeader,
  SectionSwitch,
  SuperLoadboardSettingsSection,
} from './core/SuperLoadboardSettingsSection';
import { trackUpdatedSuperLoadboardSettingsValues } from './data/SuperLoadboardAnalytics';

export interface SuperLoadboardAutoRepostCancelledProps {
  profile: ShipperProfileDTO;
}

export function SuperLoadboardAutoRepostCancelled({
  profile,
}: SuperLoadboardAutoRepostCancelledProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateLoadboardSettings } = useShipperProfileAPI();
  const canUpdateProfile = useCanExecute('UPDATE_COMPANY_PROFILE');

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateLoadboardSettings(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      trackUpdatedSuperLoadboardSettingsValues({
        name: 'Auto Repost Cancelled Automatically',
        is_auto_repost_for_cancel_offers_enabled:
          updatedValues.is_auto_repost_for_cancel_offers_enabled,
      });
      addSnackbar('Settings updated', {
        variant: 'success',
      });
    },
    onSubmitFailure(submitError) {
      addSnackbar(submitError.message, { variant: 'error' });
    },
  });

  return (
    <SuperLoadboardSettingsSection aria-label="Auto Repost Cancelled">
      <FormikProvider value={formik}>
        <Stack space="small">
          <SectionHeader>
            <Columns align="center">
              <Column width="fluid">
                <Typography color="inherit" variant="h4">
                  Repost Cancelled Offers Automatically
                </Typography>
              </Column>
              <Column width="content">
                <SectionSwitch
                  disabled={!canUpdateProfile || formik.isSubmitting}
                  checked={
                    formik.values.is_auto_repost_for_cancel_offers_enabled
                  }
                  onChange={(_, checked) => {
                    formik.setFieldValue(
                      'is_auto_repost_for_cancel_offers_enabled',
                      checked,
                    );
                    void formik.submitForm();
                  }}
                />
              </Column>
            </Columns>
          </SectionHeader>
          <Typography color="textSecondary">
            If the carrier selects any of the following cancelation reasons, the
            load will not be reposted:
            <Box sx={{ m: 0, p: 0, pl: 2 }} component="ul">
              <li>Unit Not Ready</li>
              <li>Unknown Inoperable Vehicle</li>
              <li>Carrier leaves a note</li>
            </Box>
          </Typography>
        </Stack>
      </FormikProvider>
    </SuperLoadboardSettingsSection>
  );
}
