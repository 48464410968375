import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  Tag,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useUserState } from 'shared/data/AppUserState';
import { useCanExecute } from 'shared/data/UserPermissions';
import { ReactivateIcon } from 'shared/icons/ReactivateIcon';
import { getInitials } from 'shared/utils/TextUtils';
import styled from 'styled-components';
import isEmail from 'validator/lib/isEmail';
import { UserDTO } from './data/UserDTO';
import { InvalidUsernameDialog } from './InvalidUsernameDialog';
import { UserDeactivateDialog } from './UserDeactivateDialog';
import { UserReactivateDialog } from './UserReactivateDialog';
import { UserSendPasswordResetLinkDialog } from './UserSendPasswordResetLinkDialog';

export interface UserProps {
  user: UserDTO;
  onUpdate: () => void;
}

const StyledBoxWrapper = styled(Box)`
  &:hover {
    background: ${Color.Silver100};
  }

  margin: 0 -16px;
`;

const StyledBoxOverflow = styled(Box)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledFlexBox = styled(Box)`
  align-items: center;
  justify-content: space-between;
`;

const getFullName = (user: UserDTO): string =>
  [user.first_name, user.last_name].filter(Boolean).join(' ') ||
  'Name wasn’t entered';

export function User({ user, onUpdate }: UserProps) {
  const { user: loggedUser, isAdmin, isSuperUser } = useUserState();
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);
  const [reactivateDialogOpen, setReactivateDialogOpen] = useState(false);
  const [sendResetLinkDialogOpen, setSendResetLinkDialogOpen] = useState(false);
  const [invalidUsernameDialogOpen, setInvalidUsernameDialogOpen] =
    useState(false);
  const navigate = useNavigate();
  const canManageUser = useCanExecute('USER_MANAGEMENT');
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const canManageUsers = isAdmin || isSuperUser;

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const hideMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <StyledBoxWrapper padding="small">
        <Columns space="xsmall" align="center">
          <Column width="content">
            <Avatar style={{ width: 40, height: 40 }}>
              {getInitials(
                `${user.first_name || 'N'} ${user.last_name || 'N'}`,
              )}
            </Avatar>
          </Column>

          <Column width="fluid">
            <Stack space="xxsmall">
              <Inline verticalAlign="center" space="xsmall">
                <StyledBoxOverflow>
                  <Typography component="span">
                    {loggedUser?.guid === user.guid
                      ? `You (${getFullName(user)})`
                      : `${getFullName(user)}`}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {' '}
                    {user.username}
                  </Typography>
                </StyledBoxOverflow>
                {!user.is_active && (
                  <Tag
                    aria-label="user deactivated"
                    color="red"
                    variant="subtle"
                  >
                    Deactivated
                  </Tag>
                )}
              </Inline>

              <StyledBoxOverflow>
                <Typography component="span" color="textSecondary">
                  {user.email}
                </Typography>
              </StyledBoxOverflow>

              {platform === 'mobile' && <Typography>{user.role}</Typography>}
            </Stack>
          </Column>

          <Column width="content">
            {platform === 'mobile' ? (
              canManageUser ? (
                user.is_active ? (
                  <IconButton aria-label="Edit user" onClick={showMenu}>
                    <MoreHoriz color="action" />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="Reactivate user"
                    onClick={() => {
                      setReactivateDialogOpen(true);
                    }}
                  >
                    <ReactivateIcon />
                  </IconButton>
                )
              ) : null
            ) : (
              <StyledFlexBox
                display="flex"
                width={canManageUser ? '150px' : '100px'}
              >
                <Typography>{user.role}</Typography>

                {canManageUser ? (
                  user.is_active ? (
                    <IconButton aria-label="Edit user" onClick={showMenu}>
                      <MoreHoriz color="action" />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="Reactivate user"
                      onClick={() => {
                        setReactivateDialogOpen(true);
                      }}
                    >
                      <ReactivateIcon />
                    </IconButton>
                  )
                ) : null}
              </StyledFlexBox>
            )}

            <Menu
              open={!!menuAnchor}
              anchorEl={menuAnchor}
              onClose={hideMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              disableAutoFocusItem={true}
            >
              <MenuItem
                onClick={() => {
                  navigate(`/users/edit/${user.guid}`);
                  hideMenu();
                }}
              >
                Edit
              </MenuItem>

              {canManageUsers && (
                <MenuItem
                  onClick={() => {
                    if (isEmail(user.username)) {
                      setSendResetLinkDialogOpen(true);
                    } else {
                      setInvalidUsernameDialogOpen(true);
                    }

                    hideMenu();
                  }}
                >
                  Send Password Reset Link
                </MenuItem>
              )}

              {canManageUsers && (
                <MenuItem
                  onClick={() => {
                    navigate(`/users/reset-password/${user.guid}`);
                    hideMenu();
                  }}
                >
                  Reset Password
                </MenuItem>
              )}

              {loggedUser?.guid !== user.guid && (
                <MenuItem
                  onClick={() => {
                    setDeactivateDialogOpen(true);
                    hideMenu();
                  }}
                >
                  Deactivate
                </MenuItem>
              )}
            </Menu>
          </Column>
        </Columns>
      </StyledBoxWrapper>

      <UserDeactivateDialog
        userGuid={user.guid}
        open={deactivateDialogOpen}
        onUpdate={onUpdate}
        onClose={() => setDeactivateDialogOpen(false)}
      />

      <UserReactivateDialog
        userGuid={user.guid}
        open={reactivateDialogOpen}
        onUpdate={onUpdate}
        onClose={() => setReactivateDialogOpen(false)}
      />

      <UserSendPasswordResetLinkDialog
        user={user}
        open={sendResetLinkDialogOpen}
        onClose={() => setSendResetLinkDialogOpen(false)}
      />

      <InvalidUsernameDialog
        guid={user.guid}
        open={invalidUsernameDialogOpen}
        onClose={() => setInvalidUsernameDialogOpen(false)}
      />
    </>
  );
}
