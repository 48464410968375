import { IconButton, Toolbar, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { PageLayout } from 'shared/layout/PageLayout';
import { BillingPeriodToggle } from '../BillingPeriodToggle';
import { PaymentSummaryBox } from '../PaymentSummary';
import { SubscriptionPackagesTable } from '../SubscriptionPackagesTable';
import { SubscriptionPaymentForm } from '../SubscriptionPaymentForm';
import { usePlanGroupCode } from '../usePlanGroupCode';
import { useSelectedSubscriptionPlan } from '../useSelectedSubscriptionPlan';
import { PlanProStepper } from './PlanProStepper';

export function SubscriptionPlansProPage() {
  const navigate = useNavigate();
  const [step, setStep] = useState<1 | 2>(1);

  return (
    <PageLayout
      contentBackground={Color.White}
      header={
        <Box borderBottomColor="Silver400" borderBottomWidth="small">
          <Toolbar>
            <IconButton aria-label="go back" onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>

            <Typography variant="h2">
              {step === 1 ? 'Choose Your Package' : 'Add Payment Information'}
            </Typography>
          </Toolbar>
        </Box>
      }
    >
      <Box padding="medium">
        <Stack space="large">
          <PlanProStepper
            step={step}
            onStepChange={(value) => setStep(value)}
          />
          {step === 1 && <SelectPackage onContinue={() => setStep(2)} />}
          {step === 2 && <SubscriptionPaymentForm />}
        </Stack>
      </Box>
    </PageLayout>
  );
}

interface SelectPackage {
  onContinue: () => void;
}

function SelectPackage({ onContinue }: SelectPackage) {
  const [planGroupCode, updatePlanGroupCode] = usePlanGroupCode();
  const { subscriptionPlan } = useSelectedSubscriptionPlan();

  return (
    <Stack space="large">
      <Typography variant="h2" align="center">
        Select your package based on the number of VINs
        <br />
        you deliver each month
      </Typography>
      <Box padding="xsmall">
        <BillingPeriodToggle />
      </Box>
      <Inline space="small" horizontalAlign="center">
        <SubscriptionPackagesTable
          value={planGroupCode}
          onChange={updatePlanGroupCode}
        />
        <Stack space="medium">
          <PaymentSummaryBox />

          {subscriptionPlan !== null && (
            <Button size="large" fullWidth={true} onClick={onContinue}>
              Continue to Payment
            </Button>
          )}
        </Stack>
      </Inline>
    </Stack>
  );
}
