import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { Confirmation } from 'shared/ui/Confirmation';
import { useTerminalsNavigation } from 'terminals/TerminalsRouteHelpers';
import { useTerminalsAPI } from '../data/TerminalsAPI';

interface Props {
  terminals: Set<number>;
  onSubmitSuccess: () => void;
}

export function TerminalBatchDeleteConfirmation({
  onSubmitSuccess,
  terminals,
}: Props) {
  const { addSnackbar } = useSnackbarStack();
  const { navigateToTerminals } = useTerminalsNavigation();

  const { deleteTerminals } = useTerminalsAPI();
  const deleteForm = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => deleteTerminals(Array.from(terminals)),
    onSubmitSuccess() {
      onSubmitSuccess();
      addSnackbar('Terminals deleted');
      navigateToTerminals();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Confirmation
      title={`Delete this ${formatPlural(
        terminals.size,
        'terminal',
        `${terminals.size} terminals`,
      )} ?`}
      cancelText="No"
      processText="Yes, Delete"
      onProcess={(hideConfirmation) => {
        hideConfirmation();
        deleteForm.handleSubmit();
      }}
      trigger={({ showConfirmation }) => (
        <Tooltip title="Delete">
          <IconButton
            onClick={showConfirmation}
            disabled={deleteForm.isSubmitting}
          >
            {deleteForm.isSubmitting ? (
              <CircularProgress size="24px" />
            ) : (
              <Delete color="action" aria-label="delete selected terminals" />
            )}
          </IconButton>
        </Tooltip>
      )}
    />
  );
}
