import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useResponsiveValue } from '@superdispatch/ui';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';

interface LocationState {
  back_url?: string;
}

export function OrderViewBackButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';

  function handleClick() {
    if (state && state.back_url) {
      navigate(state.back_url);
    } else if (window.history.length > 3) {
      navigate(-1);
    } else {
      navigate('/orders');
    }
  }

  return (
    <IconButton onClick={handleClick} size={isMobile ? 'small' : 'medium'}>
      <ArrowBackIcon />
    </IconButton>
  );
}
