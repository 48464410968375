import { Grid, MenuItem, Tooltip } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Button, useSnackbarStack } from '@superdispatch/ui';
import { countBy, get } from 'lodash-es';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useProductTiers } from 'shared/data/TiersUtils';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { trackEvent, trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order, { SuperPayStatus } from 'shared/types/order';
import { CurrentUser } from 'shared/types/user';
import ButtonGroupDropdown from 'shared/ui/ButtonGroupDropdown';
import styled from 'styled-components';
import { useShipperProfile } from '../../../shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../../../shipper-profile/data/ShipperProfileDTO';
import { OrderPaymentFlagDTO } from '../../data/dto/OrderPaymentFlagsDTO';
import { useBulkOrderActionAPI } from '../../data/OrderActionAPI';
import { useOrderListPaymentFlags } from '../../data/OrderPaymentFlagsAPI';
import { useAccessRestrictedState } from '../actions/AccessRestrictedDialog';
import { BulkDeleteOrderDialog } from '../actions/DeleteOrderDialog';
import { BulkMarkAsAcceptedDrawer } from '../actions/MarkAsAcceptedDrawer';
import { BulkMarkAsCarrierInvoicedPopover } from '../actions/MarkAsCarrierInvoicedPopover';
import { BulkMarkAsDeliveredPopover } from '../actions/MarkAsDeliveredPopover';
import { BulkMarkAsPaymentOnHold } from '../actions/MarkAsPaymentOnHoldDialog';
import { BulkMarkAsPickedUpPopover } from '../actions/MarkAsPickedUpPopover';
import {
  OrderActionSource,
  trackOderActionEvent,
} from '../actions/OrderActionsAnalytics';
import {
  isNewOrderWithLoads,
  showMarkAsAccepted,
  showMarkAsCarrierInvoiced,
  showSendOffer,
  showUnmarkAsCarrierInvoiced,
} from '../actions/OrderActionUtils';
import {
  isValidateAction,
  useOrderValidateAction,
  ValidateActionType,
} from '../actions/OrderValidateAction';
import {
  getAvailablePostTarget,
  PostLoadboardButton,
} from '../actions/PostLoadboardButton';
import { BulkRestoreOrderDialog } from '../actions/RestoreOrderDialog';
import { BulkSendInvoiceDrawer } from '../actions/SendInvoiceDrawer';
import { BulkSuperPayNowDialog } from '../actions/superpay-now/BulkSuperPayNowDialog';
import { SuperPayBulkNowButton } from '../actions/superpay-now/SuperPayBulkNowButton';
import {
  getAvailableUnpostTarget,
  UnpostFromLoadboardButton,
} from '../actions/UnpostFromLoadboardButton';
import { OrderErrorModal } from '../error-modal/OrderErrorModal';
import { BulkSendOfferDrawer } from '../send-offer/BulkSendOfferDrawer';
import { SingleSendOfferDrawer } from '../send-offer/SingleSendOfferDrawer';
import { BulkArchivePopover } from './BulkArchivePopover';
import { BulkCancelOfferPopover } from './BulkCancelOfferPopover';
import { BulkCarrierPriceUpdatePopover } from './BulkCarrierPriceUpdatePopover';
import { BulkDateRangeUpdatePopover } from './BulkDateRangeUpdatePopover';
import { BulkFirstAvailablePickupDateUpdatePopover } from './BulkFirstAvailablePickupDateUpdatePopover';
import { BulkMarkAsCarrierPaidDrawer } from './BulkMarkAsCarrierPaidDrawer';
import { BulkUnarchivePopover } from './BulkUnarchivePopover';

type ModalActionType =
  | 'single_send_offer'
  | 'bulk_send_offer'
  | 'send_invoice'
  | 'mark_as_accepted'
  | 'mark_as_carrier_paid'
  | 'mark_as_hold_custom_reason'
  | 'delete_order'
  | 'restore_order'
  | 'superpay_now';

type InstantActionType =
  | 'post_to_cd'
  | 'post_to_sdlb'
  | 'post_to_all'
  | 'post_to_private_loadboard'
  | 'unpost_from_cd'
  | 'unpost_from_sdlb'
  | 'unpost_from_all'
  | 'unpost_from_private_loadboard'
  | 'submit_to_broker'
  | 'mark_as_accepted'
  | 'mark_as_on_hold'
  | 'mark_as_delivery_verification'
  | 'resume_payment_group_buttons'
  | 'initiate_payment_now'
  | 'unmark_as_delivery_verification'
  | 'unmark_as_carrier_paid'
  | 'unmark_as_carrier_invoiced';

type PopoverActionType =
  | 'archive'
  | 'unarchive'
  | 'cancel_offer'
  | 'mark_as_picked_up'
  | 'mark_as_delivered'
  | 'update_first_available_pickup_date'
  | 'update_date_ranges'
  | 'update_carrier_price'
  | 'mark_as_carrier_invoiced';

export type BulkActionType =
  | ValidateActionType
  | InstantActionType
  | ModalActionType
  | PopoverActionType;

function isModalAction(type: string): type is ModalActionType {
  return [
    'single_send_offer',
    'bulk_send_offer',
    'send_invoice',
    'mark_as_accepted',
    'mark_as_carrier_paid',
    'mark_as_hold_custom_reason',
    'delete_order',
    'restore_order',
    'superpay_now',
  ].includes(type);
}

function isInstantAction(type: string): type is InstantActionType {
  return [
    'post_to_cd',
    'post_to_sdlb',
    'post_to_private_loadboard',
    'post_to_all',
    'unpost_from_cd',
    'unpost_from_sdlb',
    'unpost_from_private_loadboard',
    'unpost_from_all',
    'submit_to_broker',
    'mark_as_accepted',
    'mark_as_on_hold',
    'mark_as_delivery_verification',
    'resume_payment_group_buttons',
    'initiate_payment_now',
    'unmark_as_delivery_verification',
    'unmark_as_carrier_paid',
    'unmark_as_carrier_invoiced',
  ].includes(type);
}

function isPopoverAction(type: string): type is PopoverActionType {
  return [
    'archive',
    'unarchive',
    'cancel_offer',
    'mark_as_picked_up',
    'mark_as_delivered',
    'update_first_available_pickup_date',
    'update_date_ranges',
    'update_carrier_price',
    'mark_as_carrier_invoiced',
  ].includes(type);
}

function getInstantActionConfig(
  type: InstantActionType,
  source: OrderActionSource,
  user: CurrentUser | undefined,
  orders: Order[],
  shipper?: ShipperProfileDTO | undefined,
  args?: Record<string, unknown>,
): {
  message: string;
  submit: (
    api: ReturnType<typeof useBulkOrderActionAPI>,
    ids: number[],
  ) => Promise<Response | void>;
} {
  switch (type) {
    case 'unmark_as_carrier_paid':
      return {
        message: 'Order(s) unmarked as carrier paid',
        submit: (api, ids) =>
          api.bulkUnmarkAsCarrierPaid(ids).then(() => {
            trackEventLegacy('Bulk Unmark as Carrier Paid', {
              count: ids.length,
            });
          }),
      };
    case 'post_to_sdlb':
      return {
        message: 'Successfully Posted to Super Loadboard',
        submit: (api, ids) =>
          api.bulkPostToLoadboard(ids, ['sdlb']).then(() => {
            for (const order of orders) {
              trackOderActionEvent({
                name: '[STMS] Posted to SLB',
                order,
                source,
                user,
              });
            }
          }),
      };
    case 'post_to_private_loadboard':
      return {
        message: 'Successfully Posted to Private Loadboard',
        submit: (api, ids) => {
          const groupGuid = get(args, 'groupGuid') as string | undefined;
          return api.bulkPostToPrivateLoadboard(ids, groupGuid).then(() => {
            for (const order of orders) {
              trackOderActionEvent({
                name: '[STMS] Posted to Private Loadboard',
                order,
                source,
                shipper,
                user,
              });
            }
          });
        },
      };
    case 'post_to_cd':
      return {
        message: 'Successfully Posted to Central Dispatch',
        submit: (api, ids) =>
          api.bulkPostToLoadboard(ids, ['cd']).then(() => {
            for (const order of orders) {
              trackOderActionEvent({
                name: '[STMS] Posted to CD',
                order,
                user,
                source,
              });
            }
          }),
      };
    case 'post_to_all':
      return {
        message: 'Successfully Posted to All Loadboards',
        submit: (api, ids) =>
          api.bulkPostToLoadboard(ids, ['sdlb', 'cd']).then(() => {
            for (const order of orders) {
              trackOderActionEvent({
                name: '[STMS] Posted to CD',
                order,
                source,
                user,
              });
              trackOderActionEvent({
                name: '[STMS] Posted to SLB',
                order,
                source,
                user,
              });
            }
          }),
      };
    case 'unpost_from_sdlb':
      return {
        message: 'Unposted from Super Loadboard',
        submit: (api, ids) => api.bulkUnpostFromLoadboard(ids, ['sdlb']),
      };
    case 'unpost_from_private_loadboard':
      return {
        message: 'Unposted from Private Loadboard',
        submit: (api, ids) => api.bulkUnpostFromLoadboard(ids, ['sdlb']),
      };
    case 'unpost_from_cd':
      return {
        message: 'Unposted from Central Dispatch. It may take few minutes',
        submit: (api, ids) => api.bulkUnpostFromLoadboard(ids, ['cd']),
      };
    case 'unpost_from_all':
      return {
        message: 'Unposted from All Loadboards. It may take few minutes',
        submit: (api, ids) => api.bulkUnpostFromLoadboard(ids, ['sdlb', 'cd']),
      };
    case 'submit_to_broker':
      return {
        message: 'Order(s) submitted',
        submit: (api, ids) => api.bulkSubmitToBroker(ids),
      };
    case 'unmark_as_carrier_invoiced':
      return {
        message: 'Order(s) unmarked as carrier invoiced',
        submit: (api, ids) =>
          api.bulkUnmarkAsCarrierInvoiced(ids).then(() => {
            trackEventLegacy('Bulk Unmark as Carrier Invoiced', {
              count: orders.length,
              orders: countBy(orders, 'status'),
            });
          }),
      };
    case 'mark_as_accepted':
      return {
        message: 'Orders marked as accepted',
        submit: (api, ids) =>
          api.bulkMarkAsAccepted(ids).then(() => {
            trackEventLegacy('Bulk Mark as Accepted', {
              count: orders.length,
              orders: countBy(orders, 'status'),
            });
          }),
      };
    case 'mark_as_on_hold':
      return {
        message: 'Orders marked as on hold',
        submit: (api, ids) =>
          api.bulkMarkAsOnHold(ids).then(() => {
            trackEventLegacy('Bulk Mark as On Hold', {
              count: orders.length,
              orders: countBy(orders, 'status'),
            });
          }),
      };
    case 'resume_payment_group_buttons':
      return {
        message: 'Orders marked as resumed payment',
        submit: (api, ids) =>
          api.bulkResumePaymentGroupButtons(ids).then(() => {
            trackEvent('Shipper Bulk Resumed Payment', {
              count: orders.length,
            });
          }),
      };
    case 'initiate_payment_now':
      return {
        message: 'Orders marked as initiated payment now',
        submit: (api, ids) =>
          api.bulkInitiatePaymentNow(ids).then(() => {
            trackEventLegacy('Bulk Initiated Payment Now', {
              count: orders.length,
              orders: countBy(orders, 'status'),
            });
          }),
      };
    case 'mark_as_delivery_verification':
      return {
        message: `${orders.length} orders marked as Delivery Verified`,
        submit: (api, ids) =>
          api.bulkMarkAsDeliveryVerification(ids).then(() => {
            trackEvent('Shipper Marked as Delivery Verified', {
              utm_medium: source,
              order_count: ids.length,
            });
          }),
      };
    case 'unmark_as_delivery_verification':
      return {
        message: `${orders.length} orders unmarked as Delivery Verified`,
        submit: (api, ids) => api.bulkUnmarkAsDeliveryVerification(ids),
      };
  }
}

function showMarkAsPaymentOnHoldBulk(
  orderPaymentFlags?: OrderPaymentFlagDTO[],
) {
  if (!orderPaymentFlags?.length) {
    return false;
  }
  return orderPaymentFlags.every((order) => order.can_hold_payment);
}

function showSuperPayNow(orderPaymentFlags?: OrderPaymentFlagDTO[]) {
  return (
    !!orderPaymentFlags &&
    orderPaymentFlags.length > 0 &&
    orderPaymentFlags.every((order) => order.can_superpay_now)
  );
}

function showSuperPayNowSchedule(orderPaymentFlags?: OrderPaymentFlagDTO[]) {
  return (
    !!orderPaymentFlags &&
    orderPaymentFlags.length > 0 &&
    orderPaymentFlags.every((order) => order.can_schedule)
  );
}

function showInitiatePaymentNowBulk(orderPaymentFlags?: OrderPaymentFlagDTO[]) {
  if (!orderPaymentFlags || orderPaymentFlags.length === 0) {
    return false;
  }
  return (
    !orderPaymentFlags.every((order) => order.can_be_resumed) &&
    orderPaymentFlags.every((order) => order.can_be_initiated_now)
  );
}

function showResumePaymentBulk(orderPaymentFlags?: OrderPaymentFlagDTO[]) {
  return (
    !!orderPaymentFlags &&
    orderPaymentFlags.length > 0 &&
    orderPaymentFlags.every((order) => order.can_be_resumed)
  );
}

function showMarkAsDeliveryVerificationBulk(
  selectedOrders: Order[],
  orderPaymentFlags?: OrderPaymentFlagDTO[],
  isSystemDeliveryVerificationEnabled?: boolean,
) {
  const isSystemDeliveryVerificationFailed =
    isSystemDeliveryVerificationEnabled &&
    selectedOrders.every(
      (order) => order.delivery_verification_status === 'FAILED',
    );
  const showSuperPayMarkDeliveryVerification =
    !!orderPaymentFlags &&
    orderPaymentFlags.length > 0 &&
    orderPaymentFlags.every((order) => order.can_mark_as_delivery_verified);

  return (
    isSystemDeliveryVerificationFailed || showSuperPayMarkDeliveryVerification
  );
}

function showUnmarkAsDeliveryVerificationBulk(
  orderPaymentFlags?: OrderPaymentFlagDTO[],
) {
  return (
    !!orderPaymentFlags &&
    orderPaymentFlags.length > 0 &&
    orderPaymentFlags.every((order) => order.can_unmark_as_delivery_verified)
  );
}

function showMarkAsOnHold(order: Order): boolean {
  const availableStatuses: Array<Order['status']> = [
    'new',
    'canceled',
    'declined',
  ];

  if (order.is_on_hold || order.status === 'order_canceled') {
    return false;
  }

  return (
    availableStatuses.includes(order.status) ||
    order.is_posted_to_centraldispatch ||
    order.is_posted_to_loadboard
  );
}

function showCarrierPriceUpdate(status: string, orders: Order[]): boolean {
  const allowedStatuses: Array<Order['status']> = [
    'new',
    'canceled',
    'declined',
    'order_canceled',
  ];

  const unavailableStatuses = [
    'accepted',
    'picker_up',
    'delivered',
    'invoiced',
    'paid',
    'archived',
    'inactive',
  ];

  if (unavailableStatuses.includes(status)) {
    return false;
  }

  return orders.every(
    (order) =>
      allowedStatuses.includes(order.status) ||
      (order.status === 'pending' && order.active_offer?.is_counter_offer),
  );
}

function showDeleteOrder(status: string, orders: Order[]) {
  const deletableOrderStatuses: Array<Order['status']> = [
    'canceled',
    'declined',
    'new',
    'order_canceled',
  ];
  const deletableStatusParams = [
    'new',
    'canceled',
    'declined',
    'posted_to_lb',
    'posted_to_sdlb',
    'posted_to_cd',
    'flagged',
    'archived',
    'order_canceled',
  ];

  return (
    orders.every((order) => deletableOrderStatuses.includes(order.status)) &&
    (!status || deletableStatusParams.includes(status))
  );
}

function showFirstAvailPickupDateUpdate(
  status: string,
  selectedOrders: Order[],
) {
  const allowedStatuses: Array<Order['status']> = [
    'new',
    'canceled',
    'declined',
    'pending',
    'accepted',
    'picked_up',
    'delivered',
    'invoiced',
    'paid',
    'order_canceled',
  ];

  const unavailableStatuses = ['archived', 'inactive'];

  return (
    !unavailableStatuses.includes(status) &&
    selectedOrders.every((order) => allowedStatuses.includes(order.status))
  );
}
function showDateRangesUpdate(status: string, selectedOrders: Order[]) {
  const allowedStatuses: Array<Order['status']> = [
    'new',
    'canceled',
    'declined',
    'pending',
    'accepted',
    'picked_up',
    'delivered',
    'invoiced',
    'paid',
    'order_canceled',
  ];

  const unavailableStatuses = ['archived', 'inactive'];

  return (
    !unavailableStatuses.includes(status) &&
    selectedOrders.every((order) => allowedStatuses.includes(order.status))
  );
}

function showCombineOrdersAction(orders: Order[]) {
  const allowedStatus = ['new', 'canceled', 'order_canceled', 'declined'];
  const canCombineLoads =
    (orders.every((order) => order.is_load) ||
      orders.every((order) => !order.is_load)) &&
    (orders.every((order) => order.has_loads) ||
      orders.every((order) => !order.has_loads));

  if (orders.length < 2) {
    return false;
  }

  return (
    !orders.some(
      (order) =>
        !allowedStatus.includes(order.status) ||
        order.is_posted_to_loadboard ||
        order.is_posted_to_centraldispatch ||
        order.is_archived ||
        !order.is_active,
    ) && canCombineLoads
  );
}

function showCancelOffer(orders: Order[]) {
  const actionUnavailableStatuses: Array<Order['status']> = [
    'new',
    'canceled',
    'declined',
  ];
  const actionUnavailableSuperPayStatuses: Array<SuperPayStatus | undefined> = [
    'PAYMENT_SCHEDULED',
    'PAID',
  ];
  return orders.every(
    (order) =>
      !actionUnavailableSuperPayStatuses.includes(
        order.payment?.super_pay?.status,
      ) &&
      !actionUnavailableStatuses.includes(order.status) &&
      (order.status === 'pending' || !!order.active_offer), // pending order may have multiple pending offers but not active offer
  );
}

function showBulkSendOffer(
  status: string,
  orders: Order[],
  user: CurrentUser | undefined,
) {
  const unavailableStatuses = [
    'accepted',
    'picker_up',
    'delivered',
    'invoiced',
    'paid',
    'archived',
    'inactive',
  ];

  if (unavailableStatuses.includes(status)) {
    return false;
  }

  return orders.every((order) => showSendOffer(order, user));
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
`;

const Total = styled.div`
  min-width: 115px;
  max-width: 125px;
  color: #59616e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  line-height: 32px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 0;
  margin-left: 8px;
  padding: 0;
  width: 100%;

  .MuiButton-label {
    white-space: nowrap;
  }
`;

interface OrderBulkActionsProps {
  orders: Order[];
  source: OrderActionSource;
  onActionComplete: (type: BulkActionType) => void;
}

export function OrderBulkActions({
  source,
  onActionComplete,
  orders: selectedOrders,
}: OrderBulkActionsProps) {
  const selectedOrderIDs = useMemo(
    () => selectedOrders.map((order) => order.id),
    [selectedOrders],
  );
  const isUnifyDialogs = useFeatureToggle('payments.unify.dialogs.access.ui');
  const orderPaymentFlags = useOrderListPaymentFlags(selectedOrderIDs);
  const { data: shipper } = useShipperProfile({ staleTime: Infinity });

  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const { status = '' } = useParams<{ status: string }>();
  const bulkActionAPI = useBulkOrderActionAPI();
  const { user } = useUserState();
  const [error, setError] = useState<unknown>();

  const [instantAction, setInstantAction] = useState<{
    type: InstantActionType;
    orders: Order[];
    args?: Record<string, unknown>;
  }>();

  const [validateAction, setValidateAction] = useState<{
    type: ValidateActionType;
    orders: Order[];
    anchorEl: Element;
  }>();

  const [popoverAction, setPopoverAction] = useState<{
    type: PopoverActionType;
    orders: Order[];
    anchorEl: Element;
  }>();

  const [modalAction, setModalAction] = useState<{
    type: ModalActionType;
    orders: Order[];
  }>();

  const instantActionConfig = useMemo(() => {
    if (instantAction?.type) {
      return getInstantActionConfig(
        instantAction.type,
        source,
        user,
        instantAction.orders,
        shipper,
        instantAction.args,
      );
    }
    return undefined;
  }, [instantAction, source, user]);

  const isBroker = user?.shipper.shipper_type === 'BROKER';
  const isCustomer = user?.shipper.shipper_type === 'CUSTOMER';
  const selectedOrdersIds = selectedOrders.map((order) => order.id);
  const selectedTotal = selectedOrdersIds.length;
  const hasParentOrders = selectedOrders.some(
    (order) => order.has_loads && !order.is_load,
  );
  const ordersText = selectedTotal > 1 ? 'orders' : 'order';

  const { isAdvancedTier } = useProductTiers();
  const isSystemDeliveryVerificationEnabled = useFeatureToggle(
    'stms.system.delivery.verification.enabled',
  );

  const postTarget = useMemo(
    () => getAvailablePostTarget(selectedOrders),
    [selectedOrders],
  );
  const unpostTarget = useMemo(
    () => getAvailableUnpostTarget(selectedOrders),
    [selectedOrders],
  );

  const instantForm = useFormikEnhanced<{ orders?: Order[] }, Response | void>({
    initialValues: { orders: instantAction?.orders },
    onSubmit({ orders }) {
      if (orders && instantActionConfig) {
        const ids = orders.map((x) => x.id);
        return instantActionConfig.submit(bulkActionAPI, ids);
      }
      return Promise.reject(new Error('Order not selected'));
    },
    onSubmitSuccess() {
      addSnackbar(instantActionConfig?.message, { variant: 'success' });
      setInstantAction(undefined);

      if (instantAction) {
        onActionComplete(instantAction.type);
      }
    },
    onSubmitFailure(failureError) {
      addSnackbar(<APIErrorMessage error={failureError} />, {
        variant: 'error',
      });
    },
  });

  const { isValidating, onValidate } = useOrderValidateAction({
    onComplete() {
      if (validateAction) {
        const { type, orders, anchorEl } = validateAction;

        setValidateAction(undefined);

        if (type === 'mark_as_accepted') {
          if (orders.every((order) => !!order.active_offer)) {
            setInstantAction({ type, orders });
          } else {
            setModalAction({ type, orders });
          }
        } else if (type === 'combine_orders') {
          const guids = orders.map((x) => x.guid).join(',');
          navigate(`/orders/combine?guids=${guids}`);
        } else if (type === 'send_offer') {
          if (orders.length > 1) {
            setModalAction({ type: 'bulk_send_offer', orders });
          } else {
            setModalAction({ type: 'single_send_offer', orders });
          }
        } else if (isInstantAction(type)) {
          setInstantAction({ type, orders });
        } else if (isModalAction(type)) {
          setModalAction({ type, orders });
        } else if (isPopoverAction(type)) {
          setPopoverAction({ type, orders, anchorEl });
        }
      }
    },
    onFailure(failureError) {
      setError(failureError);
    },
  });
  const { handleSubmit } = instantForm;

  useEffect(() => {
    if (validateAction) {
      onValidate(validateAction.type, validateAction.orders);
    }
  }, [validateAction, onValidate]);

  useEffect(() => {
    if (instantAction) {
      handleSubmit();
    }
  }, [instantAction, handleSubmit]);

  function getModalOrders(type: BulkActionType) {
    return modalAction?.type === type ? modalAction.orders : undefined;
  }

  function getLoading(type: BulkActionType) {
    return (
      (validateAction?.type === type && isValidating) ||
      (instantAction?.type === type && instantForm.isSubmitting)
    );
  }

  function handleAction(type: BulkActionType, args?: Record<string, unknown>) {
    return (event: MouseEvent) => {
      if (isValidateAction(type)) {
        setValidateAction({
          type,
          orders: selectedOrders,
          anchorEl: event.currentTarget,
        });
      } else if (isInstantAction(type)) {
        setInstantAction({ type, args, orders: selectedOrders });
      } else if (isPopoverAction(type)) {
        setPopoverAction({
          type,
          orders: selectedOrders,
          anchorEl: event.currentTarget,
        });
      } else if (isModalAction(type)) {
        setModalAction({
          type,
          orders: selectedOrders,
        });
      }
    };
  }

  function handleActionComplete(type: BulkActionType) {
    return () => {
      setModalAction(undefined);
      setValidateAction(undefined);
      setPopoverAction(undefined);
      onActionComplete(type);
    };
  }

  function hideModal() {
    setModalAction(undefined);
  }

  function hidePopover() {
    setPopoverAction(undefined);
  }

  function getPopoverProps(x: BulkActionType) {
    return {
      onClose: hidePopover,
      onSubmitSuccess: handleActionComplete(x),
      anchorOrigin: { horizontal: 'left', vertical: 'bottom' } as const,
      orders: popoverAction?.type === x ? popoverAction.orders : undefined,
      anchorEl: popoverAction?.type === x ? popoverAction.anchorEl : null,
    };
  }

  const { withAccessRestriction } = useAccessRestrictedState();

  return (
    <Wrap aria-label="order bulk actions">
      <Total>
        {selectedTotal} {ordersText} selected
      </Total>
      <StyledGrid container={true} spacing={1}>
        {isBroker && (
          <>
            {selectedOrders.every((order) =>
              showUnmarkAsCarrierInvoiced(order),
            ) && (
              <Grid item={true}>
                <Tooltip title="Only manually marked orders will be unmarked.">
                  <Button
                    color="primary"
                    variant="outlined"
                    isLoading={getLoading('unmark_as_carrier_invoiced')}
                    onClick={handleAction('unmark_as_carrier_invoiced')}
                  >
                    Unmark as Carrier Invoiced
                  </Button>
                </Tooltip>
              </Grid>
            )}

            {selectedOrders.every((order) => order.status === 'paid') && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('unmark_as_carrier_paid')}
                  onClick={handleAction('unmark_as_carrier_paid')}
                >
                  Unmark as Carrier Paid
                </Button>
              </Grid>
            )}

            {showBulkSendOffer(status, selectedOrders, user) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('send_offer')}
                  onClick={(e) => {
                    withAccessRestriction(() => {
                      trackEventLegacy('Opened Send Offer Drawer', {
                        utm_medium: 'orders_list_bulk',
                      });
                      handleAction('send_offer')(e);
                    });
                  }}
                >
                  Send offer
                </Button>
              </Grid>
            )}

            {status !== 'inactive' &&
              !hasParentOrders &&
              postTarget.length > 0 && (
                <Grid item={true}>
                  <PostLoadboardButton
                    target={postTarget}
                    source="orders_list_bulk"
                    onClick={(event, type, args) => {
                      withAccessRestriction(() =>
                        handleAction(type, args)(event),
                      );
                    }}
                    isLoading={
                      getLoading('post_to_cd') ||
                      getLoading('post_to_private_loadboard') ||
                      getLoading('post_to_sdlb') ||
                      getLoading('post_to_all')
                    }
                  />
                </Grid>
              )}

            {status !== 'inactive' && !hasParentOrders && unpostTarget && (
              <Grid item={true}>
                <UnpostFromLoadboardButton
                  target={unpostTarget}
                  source="orders_list_bulk"
                  onClick={(type, event) => handleAction(type)(event)}
                  isLoading={
                    getLoading('unpost_from_private_loadboard') ||
                    getLoading('unpost_from_cd') ||
                    getLoading('unpost_from_sdlb') ||
                    getLoading('unpost_from_all')
                  }
                />
              </Grid>
            )}

            {showSuperPayNow(orderPaymentFlags) && (
              <Grid item={true}>
                <SuperPayBulkNowButton
                  source={source}
                  onSuperPayNow={handleAction('superpay_now')}
                  canScheduleSuperPay={showSuperPayNowSchedule(
                    orderPaymentFlags,
                  )}
                  orders={selectedOrders}
                />
              </Grid>
            )}

            {showMarkAsDeliveryVerificationBulk(
              selectedOrders,
              orderPaymentFlags,
              isSystemDeliveryVerificationEnabled,
            ) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('mark_as_delivery_verification')}
                  onClick={handleAction('mark_as_delivery_verification')}
                >
                  Mark as Delivery Verified
                </Button>
              </Grid>
            )}

            {showUnmarkAsDeliveryVerificationBulk(orderPaymentFlags) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('unmark_as_delivery_verification')}
                  onClick={handleAction('unmark_as_delivery_verification')}
                >
                  Unmark as Delivery Verified
                </Button>
              </Grid>
            )}

            {showResumePaymentBulk(orderPaymentFlags) && (
              <Grid item={true}>
                <ButtonGroupDropdown
                  label="Resume Payment"
                  color="primary"
                  variant="outlined"
                  isLoading={
                    getLoading('resume_payment_group_buttons') ||
                    getLoading('superpay_now') ||
                    getLoading('initiate_payment_now')
                  }
                  onClick={handleAction('resume_payment_group_buttons')}
                >
                  <MenuItem
                    onClick={handleAction(
                      isUnifyDialogs ? 'superpay_now' : 'initiate_payment_now',
                    )}
                  >
                    {isUnifyDialogs ? 'SuperPay Now' : 'Initiate Payment Now'}
                  </MenuItem>
                </ButtonGroupDropdown>
              </Grid>
            )}

            {showInitiatePaymentNowBulk(orderPaymentFlags) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading(
                    isUnifyDialogs ? 'superpay_now' : 'initiate_payment_now',
                  )}
                  onClick={handleAction(
                    isUnifyDialogs ? 'superpay_now' : 'initiate_payment_now',
                  )}
                >
                  {isUnifyDialogs ? 'SuperPay Now' : 'Initiate Payment Now'}
                </Button>
              </Grid>
            )}

            {showMarkAsPaymentOnHoldBulk(orderPaymentFlags) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('mark_as_hold_custom_reason')}
                  onClick={handleAction('mark_as_hold_custom_reason')}
                >
                  Mark as Payment on Hold
                </Button>
              </Grid>
            )}

            {showFirstAvailPickupDateUpdate(status, selectedOrders) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('update_first_available_pickup_date')}
                  onClick={handleAction('update_first_available_pickup_date')}
                >
                  1st Avail. Pickup Date
                </Button>
              </Grid>
            )}

            {showDateRangesUpdate(status, selectedOrders) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('update_date_ranges')}
                  onClick={handleAction('update_date_ranges')}
                >
                  Change Date Ranges
                </Button>
              </Grid>
            )}

            {showCarrierPriceUpdate(status, selectedOrders) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('update_carrier_price')}
                  onClick={handleAction('update_carrier_price')}
                >
                  Increase Price by
                </Button>
              </Grid>
            )}

            {selectedOrders.every(showMarkAsOnHold) && isAdvancedTier && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('mark_as_on_hold')}
                  onClick={handleAction('mark_as_on_hold')}
                >
                  Mark as On Hold
                </Button>
              </Grid>
            )}

            {selectedOrders.every(showMarkAsAccepted) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('mark_as_accepted')}
                  onClick={handleAction('mark_as_accepted')}
                >
                  Mark as Accepted
                </Button>
              </Grid>
            )}

            {selectedOrders.every(
              (order) =>
                order.status === 'accepted' || isNewOrderWithLoads(order),
            ) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('mark_as_picked_up')}
                  onClick={handleAction('mark_as_picked_up')}
                >
                  Mark as Picked Up
                </Button>
              </Grid>
            )}

            {(selectedOrders.every(
              (order) =>
                order.status === 'accepted' || isNewOrderWithLoads(order),
            ) ||
              selectedOrders.every(
                (order) => order.status === 'picked_up',
              )) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('mark_as_delivered')}
                  onClick={handleAction('mark_as_delivered')}
                >
                  Mark as Delivered
                </Button>
              </Grid>
            )}

            {status !== 'inactive' && isAdvancedTier && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('send_invoice')}
                  onClick={handleAction('send_invoice')}
                >
                  Send Customer Invoice
                </Button>
              </Grid>
            )}

            {showCombineOrdersAction(selectedOrders) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('combine_orders')}
                  onClick={handleAction('combine_orders')}
                >
                  Combine
                </Button>
              </Grid>
            )}

            {selectedOrders.every((order) =>
              showMarkAsCarrierInvoiced(order),
            ) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('mark_as_carrier_invoiced')}
                  onClick={handleAction('mark_as_carrier_invoiced')}
                >
                  Mark as Carrier Invoiced
                </Button>
              </Grid>
            )}

            {selectedOrders.every(
              (order) =>
                order.status === 'delivered' || order.status === 'invoiced',
            ) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('mark_as_carrier_paid')}
                  onClick={handleAction('mark_as_carrier_paid')}
                >
                  Mark as Carrier Paid
                </Button>
              </Grid>
            )}

            {status !== 'archived' &&
              status !== 'inactive' &&
              selectedOrders.every((order) => !order.is_archived) && (
                <Grid item={true}>
                  <Button
                    color="primary"
                    variant="outlined"
                    isLoading={getLoading('archive')}
                    onClick={handleAction('archive')}
                  >
                    Archive
                  </Button>
                </Grid>
              )}

            {(status === 'archived' || source === 'Order View') &&
              selectedOrders.every((order) => order.is_archived) && (
                <Grid item={true}>
                  <Button
                    color="primary"
                    variant="outlined"
                    isLoading={getLoading('unarchive')}
                    onClick={handleAction('unarchive')}
                  >
                    Unarchive
                  </Button>
                </Grid>
              )}

            {status !== 'archived' &&
              status !== 'inactive' &&
              showCancelOffer(selectedOrders) && (
                <Grid item={true}>
                  <Button
                    color="primary"
                    variant="outlined"
                    isLoading={getLoading('cancel_offer')}
                    onClick={handleAction('cancel_offer')}
                  >
                    Cancel Offer
                  </Button>
                </Grid>
              )}

            {showDeleteOrder(status, selectedOrders) && (
              <Grid item={true}>
                <Button
                  color="primary"
                  variant="outlined"
                  isLoading={getLoading('delete_order')}
                  onClick={handleAction('delete_order')}
                >
                  Delete
                </Button>
              </Grid>
            )}

            {status === 'inactive' &&
              selectedOrders.every((order) => !order.is_active) && (
                <Grid item={true}>
                  <Button
                    color="primary"
                    variant="outlined"
                    isLoading={getLoading('restore_order')}
                    onClick={handleAction('restore_order')}
                  >
                    Restore
                  </Button>
                </Grid>
              )}
          </>
        )}
        {isCustomer && selectedOrders.every((x) => x.status === 'new') && (
          <Button
            color="primary"
            variant="outlined"
            isLoading={getLoading('submit_to_broker')}
            onClick={handleAction('submit_to_broker')}
          >
            Submit
          </Button>
        )}
      </StyledGrid>

      <BulkDeleteOrderDialog
        onCancel={hideModal}
        orders={getModalOrders('delete_order')}
        onSubmitSuccess={handleActionComplete('delete_order')}
      />

      <BulkRestoreOrderDialog
        onCancel={hideModal}
        orders={getModalOrders('restore_order')}
        onSubmitSuccess={handleActionComplete('restore_order')}
      />

      <BulkMarkAsPaymentOnHold
        onClose={hideModal}
        orders={getModalOrders('mark_as_hold_custom_reason')}
        onSubmitSuccess={handleActionComplete('mark_as_hold_custom_reason')}
      />

      <BulkSuperPayNowDialog
        onClose={hideModal}
        orders={getModalOrders('superpay_now')}
        onSubmitSuccess={handleActionComplete('superpay_now')}
      />

      <BulkSendInvoiceDrawer
        onClose={hideModal}
        orders={getModalOrders('send_invoice')}
        onSubmitSuccess={handleActionComplete('send_invoice')}
      />

      <BulkMarkAsAcceptedDrawer
        onClose={hideModal}
        orders={getModalOrders('mark_as_accepted')}
        onSubmitSuccess={handleActionComplete('mark_as_accepted')}
      />

      <BulkMarkAsCarrierPaidDrawer
        onClose={hideModal}
        orders={getModalOrders('mark_as_carrier_paid')}
        onSubmitSuccess={handleActionComplete('mark_as_carrier_paid')}
      />

      <SingleSendOfferDrawer
        source={source}
        onClose={hideModal}
        order={getModalOrders('single_send_offer')?.[0]}
        onSubmitSuccess={handleActionComplete('single_send_offer')}
      />

      <BulkSendOfferDrawer
        source={source}
        onClose={hideModal}
        orders={getModalOrders('bulk_send_offer')}
        onSubmitSuccess={handleActionComplete('bulk_send_offer')}
      />

      <BulkMarkAsPickedUpPopover {...getPopoverProps('mark_as_picked_up')} />

      <BulkMarkAsDeliveredPopover {...getPopoverProps('mark_as_delivered')} />

      <BulkMarkAsCarrierInvoicedPopover
        {...getPopoverProps('mark_as_carrier_invoiced')}
      />

      <BulkArchivePopover {...getPopoverProps('archive')} />

      <BulkUnarchivePopover {...getPopoverProps('unarchive')} />

      <BulkCancelOfferPopover {...getPopoverProps('cancel_offer')} />

      <BulkCarrierPriceUpdatePopover
        {...getPopoverProps('update_carrier_price')}
      />

      <BulkFirstAvailablePickupDateUpdatePopover
        {...getPopoverProps('update_first_available_pickup_date')}
      />
      <BulkDateRangeUpdatePopover {...getPopoverProps('update_date_ranges')} />

      <OrderErrorModal error={error} onCancel={() => setError(undefined)} />
    </Wrap>
  );
}
