import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { parseDate } from '@superdispatch/dates';
import { Column, Columns, Inline } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useOnboarding } from 'shared/context/OnboardingContext';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useSubscriptionDetails } from 'shared/errors/paywall/data/PaywallAPI';
import { useHasPaywall } from 'shared/errors/paywall/PaywallEvents';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';

export function SelfServeBanner() {
  const { pathname } = useLocation();
  const { data } = useSubscriptionDetails();
  const { hasCompletedAllSteps } = useOnboarding();
  const today = DateTime.local();
  const expirationDate = parseDate(data?.chargebee.trial_end);
  const daysLeft = Math.round(expirationDate.diff(today).as('days'));
  const isTrialReminderClosed = useIsTrialReminderClosed(daysLeft);
  const hasPaywall = useHasPaywall();
  const isOnboardingPage = pathname === '/getting-started';

  const showTrialReminder =
    data?.chargebee.status === 'IN_TRIAL' &&
    daysLeft <= 7 &&
    !isTrialReminderClosed;

  if (showTrialReminder) {
    return <TrialEndReminderBanner daysLeft={daysLeft} />;
  }

  if (!isOnboardingPage && !hasCompletedAllSteps && !hasPaywall) {
    return <OnboardingReminderBanner />;
  }

  return null;
}

function OnboardingReminderBanner() {
  const navigate = useNavigate();
  const { isVerified, completedStepsCount, totalStepsCount } = useOnboarding();
  const isNewOnboardingEnabled = useFeatureToggle(
    'self-serve-onboarding-v2.enabled',
  );

  if (isNewOnboardingEnabled) {
    return (
      <Box backgroundColor="Dark500" padding="small" color="Silver500">
        <Inline space="xsmall" horizontalAlign="center" verticalAlign="center">
          <Typography>
            {isVerified
              ? "You haven't posted any orders yet."
              : "You haven't submitted your verification documents yet."}
          </Typography>
          <Button
            onClick={() => navigate('/getting-started')}
            variant="neutral"
          >
            {isVerified ? 'Create and Post Order' : 'Become Verified'}
          </Button>
        </Inline>
      </Box>
    );
  }

  return (
    <Box backgroundColor="Dark500" padding="small" color="Silver500">
      <Inline space="xsmall" horizontalAlign="center" verticalAlign="center">
        <Typography>
          {completedStepsCount}/{totalStepsCount} setup steps completed.
          Complete all the necessary steps.
        </Typography>
        <Button onClick={() => navigate('/getting-started')} variant="neutral">
          Continue Setup
        </Button>
      </Inline>
    </Box>
  );
}

export function TrialEndReminderBanner({ daysLeft }: { daysLeft: number }) {
  const navigate = useNavigate();

  function handleClose() {
    writeIsTrialReminderClosed(daysLeft);
  }

  return (
    <Box backgroundColor="Blue500" padding="small" color="White">
      <Columns align="center">
        <Column>
          <Inline
            space="xsmall"
            horizontalAlign="center"
            verticalAlign="center"
          >
            <Typography>
              You have {daysLeft} {formatPlural(daysLeft, 'day', 'days')} left
              in your free trial. Upgrade now to unlock all the advanced
              features of Shipper TMS and maximize your benefits!
            </Typography>
            <Button
              onClick={() => navigate('/subscription-plans')}
              variant="neutral"
            >
              Subscribe Now
            </Button>
          </Inline>
        </Column>
        <Column width="content">
          <IconButton onClick={handleClose} size="small">
            <Close htmlColor="white" />
          </IconButton>
        </Column>
      </Columns>
    </Box>
  );
}

const BANNER_CLOSED_STORAGE_KEY = 'is_trial_reminder_closed';
const DAILY_TRIAL_BANNER_CLOSED_STORAGE_KEY = 'is_daily_trial_reminder_closed';
const daysMap: Record<number, string> = {
  7: '7_days',
  3: '3_days',
  1: '1_day',
};

function useIsTrialReminderClosed(daysLeft: number) {
  const dailyReminder = useStorageValue(DAILY_TRIAL_BANNER_CLOSED_STORAGE_KEY);
  const reminder = useStorageValue(BANNER_CLOSED_STORAGE_KEY);

  if (daysMap[daysLeft]) {
    return dailyReminder === daysMap[daysLeft];
  }

  return !!reminder && reminder !== 'false';
}

function writeIsTrialReminderClosed(daysLeft: number) {
  if (daysMap[daysLeft]) {
    writeStorageItem(
      DAILY_TRIAL_BANNER_CLOSED_STORAGE_KEY,
      getStorageValueByDay(daysLeft),
    );
  }
  writeStorageItem(BANNER_CLOSED_STORAGE_KEY, 'true');
}

function getStorageValueByDay(daysLeft: number) {
  return daysMap[daysLeft] || 'true';
}
