import { plainToClass } from 'class-transformer';
import { APIPageResponse, useAPI } from 'shared/api/API';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import { useAPIQuery, UseAPIQueryOptions } from 'shared/api/APIQuery';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useProductTiers } from 'shared/data/TiersUtils';
import { Order } from 'shared/types/order';
import { InstantDispatchCarriersDTO } from './dto/InstantDispatchDTO';
import { useSingleOrderActionAPI } from './OrderActionAPI';

function canInstantDispatchOrder(order: Order | undefined): boolean {
  if (!order) return false;

  const isParentOrder = order.has_loads && !order.is_load;
  if (isParentOrder) return false;

  return (
    order.is_on_hold ||
    order.status === 'declined' ||
    order.status === 'canceled' ||
    order.status === 'new'
  );
}

export function useInstantDispatchCarriers(
  order: Order | undefined,
  options?: UseAPIQueryOptions<
    APIPageResponse<InstantDispatchCarriersDTO>['data']
  >,
) {
  const { requestPage } = useAPI();
  const hasInstantDispatchAccess = useFeatureToggle(
    'instant-dispatch.access.ui',
  );
  const { isAdvancedTier } = useProductTiers();
  const isEnabled =
    isAdvancedTier &&
    hasInstantDispatchAccess &&
    canInstantDispatchOrder(order);

  return useAPIQuery(
    [
      'order',
      'instant-dispatch-carriers',
      {
        guid: order?.guid,
        status: order?.status,
        is_on_hold: order?.is_on_hold,
        vehicles: order?.vehicles,
        pickup: order?.pickup,
        delivery: order?.delivery,
      },
    ],
    () => {
      return requestPage(
        'GET /internal/orders/{orderGuid}/instant_dispatch/carriers',
        (data) => plainToClass(InstantDispatchCarriersDTO, data),
        { orderGuid: order?.guid },
      );
    },
    {
      ...options,
      enabled: isEnabled,
      keepPreviousData: true,
    },
  );
}

export function useCanInstantDispatch(order: Order | undefined): boolean {
  const { data: carriers } = useInstantDispatchCarriers(order);
  return !!carriers?.objects.length;
}

export function useInstantDispatchMutation(
  options?: APIMutationOptions<
    [
      orderGuid: string,
      carrierGuid: string,
      laneGuid: string,
      expectedPrice: number,
    ]
  >,
) {
  const { instantDispatch } = useSingleOrderActionAPI();
  return useAPIMutation<
    [
      orderGuid: string,
      carrierGuid: string,
      laneGuid: string,
      expectedPrice: number,
    ]
  >((args) => instantDispatch(...args), options);
}
