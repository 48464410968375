import { Route, Routes } from 'react-router-dom-v5-compat';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { BankAccountPage } from './bank-account/BankAccountPage';
import { BusinessDetailsPage } from './business-details/BusinessDetailsPage';
import { MoovServiceContextProvider } from './MoovServiceContext';
import { OwnersListPage } from './owners/OwnersListPage';
import { SuperPayPromoPage } from './promo/SuperPayPromoPage';
import { ROUTES_SUPERPAY, SuperPayRoute } from './SuperPayRoute';
import { SuperPaySettingsPage } from './SuperPaySettingsPage';

export const SuperPayContainer = () => {
  useDocumentTitle('SuperPay');

  return (
    <MoovServiceContextProvider>
      <Routes>
        <Route
          path="business-details"
          element={
            // The 'path' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6.
            <SuperPayRoute path={ROUTES_SUPERPAY.businessDetails}>
              <BusinessDetailsPage />
            </SuperPayRoute>
          }
        />
        <Route
          path="owners"
          element={
            <SuperPayRoute path={ROUTES_SUPERPAY.owners}>
              <OwnersListPage />
            </SuperPayRoute>
          }
        />
        <Route
          path="bank-details/*"
          element={
            <SuperPayRoute path={ROUTES_SUPERPAY.bankDetails}>
              <SuperPaySettingsPage />
            </SuperPayRoute>
          }
        />
        <Route
          path="bank-account"
          element={
            <SuperPayRoute path={ROUTES_SUPERPAY.bankAccount}>
              <BankAccountPage />
            </SuperPayRoute>
          }
        />
        <Route
          path="*"
          element={
            <SuperPayRoute path={ROUTES_SUPERPAY.main}>
              <SuperPayPromoPage />
            </SuperPayRoute>
          }
        />
      </Routes>
    </MoovServiceContextProvider>
  );
};
