import { Dialog, Typography } from '@material-ui/core';
import { parseDate } from '@superdispatch/dates';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { atom, useAtom } from 'jotai';
import { DateTime } from 'luxon';
import SubscriptionPaymentDueIllustration from 'orders/assets/subscription_payment_due.png';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useBillingStatus } from 'shared/errors/paywall/data/PaywallAPI';

const dialogAtom = atom(false);

export function AccessRestrictedDialog() {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useAtom(dialogAtom);
  const platform = useResponsiveValue('mobile', 'other');

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      fullScreen={platform === 'mobile'}
    >
      <Box padding="large" maxWidth="480px">
        <Stack space="large" align="center">
          <img
            width="270px"
            height="100px"
            src={SubscriptionPaymentDueIllustration}
          />
          <Stack space="xsmall" align="center">
            <Typography variant="h2">Action Needed</Typography>
            <Typography align="center">
              Your recent payment attempt was unsuccessful. Please update your
              payment method to continue to access the Super Loadboard.
            </Typography>
          </Stack>

          <Inline space="small">
            <Button variant="neutral" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
                navigate({
                  pathname: '/subscription',
                  search: 'utm_medium=Access Restricted Dialog',
                });
              }}
            >
              Update Payment Method
            </Button>
          </Inline>
        </Stack>
      </Box>
    </Dialog>
  );
}

export function useAccessRestrictedState() {
  const { data: billingStatus } = useBillingStatus();
  const [_, openDialog] = useAtom(dialogAtom);
  const isRestrictionEnabled = useFeatureToggle(
    'subscription-past-due-payment.enabled',
  );
  const { user } = useUserState();
  const isSelfServe = user?.shipper.is_self_serve;

  const isNotPaid =
    isRestrictionEnabled &&
    (billingStatus?.status === 'NOT_PAID' ||
      billingStatus?.status === 'PAYMENT_DUE');

  const today = DateTime.local();
  const dueDate = parseDate(billingStatus?.due_date);
  const isPastDuePayment = isNotPaid && today.diff(dueDate).as('days') > 14;

  const withAccessRestriction = (action: () => void) => {
    if (!isSelfServe) {
      action();
      return;
    }

    if (isPastDuePayment) {
      openDialog(true);
    } else {
      openDialog(false);
      action();
    }
  };

  return {
    withAccessRestriction,
  };
}
