import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  ExitTransitionPlaceholder,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, DescriptionLineItem } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { Form, FormikProvider } from 'formik';
import { useSingleOrderActionAPI } from 'orders/data/OrderActionAPI';
import { useDepositExpectedDate } from 'orders/data/OrderAPI';
import { useEffect } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { SuperPayLogo } from 'shared/icons/SuperPayLogo';
import Order from 'shared/types/order';
import { SuperPayNowPreview } from './SuperPayNowPreview';

interface SingleSuperPayNowDialogContentProps {
  order: Order;
  onClose: () => void;
  onSuccess: () => void;
}
function SingleSuperPayNowDialogContent({
  order,
  onClose,
  onSuccess,
}: SingleSuperPayNowDialogContentProps) {
  const isUnifyDialogs = useFeatureToggle('payments.unify.dialogs.access.ui');
  const { data } = useDepositExpectedDate({ enabled: !isUnifyDialogs });
  const { superPayNow } = useSingleOrderActionAPI();
  const { addSnackbar } = useSnackbarStack();

  const nonSuperPayOrdersCount = Number(order.payment?.method !== 'superpay');

  useEffect(() => {
    trackEvent('Shipper Opened SuperPay Now');
  }, []);

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => {
      return superPayNow(order.id);
    },
    onSubmitSuccess: () => {
      addSnackbar('Order updated', { variant: 'success' });
      trackEvent('Shipper SuperPaid Now', {
        change_to_SuperPay: nonSuperPayOrdersCount,
      });
      onSuccess();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <DialogTitle>
          <Typography variant="h3">SuperPay Now</Typography>
        </DialogTitle>
        {isUnifyDialogs ? (
          <SuperPayNowPreview
            orders={[order]}
            nonSuperPayOrdersCount={nonSuperPayOrdersCount}
          />
        ) : (
          <DialogContent>
            <Stack space="small">
              <DescriptionLineItem
                title={
                  <Typography color="textSecondary">Total Charge</Typography>
                }
              >
                <Typography variant="h5">
                  <Price value={order.price} />
                </Typography>
              </DescriptionLineItem>
              <DescriptionLineItem
                title={
                  <Typography color="textSecondary">
                    Deposit Expected
                  </Typography>
                }
              >
                <Typography variant="h5">
                  <FormattedDate
                    variant="Date"
                    format="DateISO"
                    date={data?.expected_date}
                  />
                </Typography>
              </DescriptionLineItem>

              <Divider />
              <Stack space="xsmall">
                <Typography color="textSecondary">
                  Order changes to <SuperPayLogo /> · 1-3 Business Days
                </Typography>
                <Typography color="textSecondary">
                  Inspection type will be changed to Advanced.
                </Typography>
              </Stack>

              <Columns space="xxsmall">
                <Column width="content">
                  <Box position="relative" top="2px">
                    <Info fontSize="small" color="action" />
                  </Box>
                </Column>
                <Column>
                  <Typography>
                    Interested in paying with longer SuperPay terms? Please{' '}
                    <Link
                      color="primary"
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://forms.gle/7uA3LAaxqVtDjNbW7"
                    >
                      click here.
                    </Link>
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </DialogContent>
        )}

        <DialogActions>
          <Button
            variant="neutral"
            onClick={() => {
              trackEvent('Shipper Canceled SuperPay Now Dialog');
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" pending={formik.isSubmitting}>
            SuperPay Now
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}

interface SingleSuperPayNowDialogProps {
  order?: Order;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function SingleSuperPayNowDialog({
  order,
  onClose,
  onSubmitSuccess,
}: SingleSuperPayNowDialogProps) {
  return (
    <Dialog open={!!order} onClose={onClose}>
      <Box width={['auto', '400px']}>
        <ExitTransitionPlaceholder in={!!order}>
          {!!order && (
            <SingleSuperPayNowDialogContent
              order={order}
              onClose={onClose}
              onSuccess={onSubmitSuccess}
            />
          )}
        </ExitTransitionPlaceholder>
      </Box>
    </Dialog>
  );
}
