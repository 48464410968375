import { DialogContent, Divider, Link, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, DescriptionLineItem } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { useDepositExpectedDate } from 'orders/data/OrderAPI';
import { useMemo } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { SuperPayLogo } from 'shared/icons/SuperPayLogo';
import Order from 'shared/types/order';

interface AutomaticFlowDialogProps {
  orders: Order[];
  price: number;
}

export function AutomaticFlowDialogContent({
  orders,
  price,
}: AutomaticFlowDialogProps) {
  const { data } = useDepositExpectedDate();
  const ordersCount = orders.length;
  const formattedOrdersText = formatPlural(ordersCount, 'order', 'orders');
  const nonSuperPayOrders = useMemo(
    () => orders.filter((order) => order.payment?.method !== 'superpay').length,
    [orders],
  );

  return (
    <DialogContent>
      <Stack space="small">
        <Typography>
          {`${ordersCount} ${formattedOrdersText} will be SuperPaid Now.`}
        </Typography>

        <DescriptionLineItem
          title={
            <Typography color="textSecondary">
              {ordersCount > 1
                ? `Total Charge (${ordersCount} orders)`
                : 'Total Charge'}
            </Typography>
          }
        >
          <Typography variant="h5">
            <Price value={price} />
          </Typography>
        </DescriptionLineItem>
        <DescriptionLineItem
          title={
            <Typography color="textSecondary">Deposit Expected</Typography>
          }
        >
          <Typography variant="h5">
            <FormattedDate
              variant="Date"
              format="DateISO"
              date={data?.expected_date}
            />
          </Typography>
        </DescriptionLineItem>

        {nonSuperPayOrders > 0 && (
          <>
            <Divider />
            <Stack space="xsmall">
              <NonSuperPayOrdersInfo ordersCount={nonSuperPayOrders} />
              <Typography color="textSecondary">
                Inspection type will be changed to Advanced.
              </Typography>
            </Stack>
          </>
        )}
        <LongerTermsInfo />
      </Stack>
    </DialogContent>
  );
}

interface ManualFlowDialogProps {
  orders: Order[];
  price: number;
}

export function ManualFlowDialogContent({
  orders,
  price,
}: ManualFlowDialogProps) {
  const { data } = useDepositExpectedDate();
  const ordersCount = orders.length;
  const nonSuperPayOrders = useMemo(
    () => orders.filter((order) => order.payment?.method !== 'superpay').length,
    [orders],
  );
  const isEqualNonSuperPayAndAllOrders = nonSuperPayOrders === ordersCount;
  const totalChargeText =
    ordersCount > 1 ? `Total Charge (${ordersCount} orders)` : `Total Charge`;

  return (
    <DialogContent>
      <Stack space="small">
        <Typography>
          {ordersCount} {formatPlural(ordersCount, 'order', 'orders')} will be
          SuperPaid Now.
        </Typography>

        <DescriptionLineItem
          title={
            <Typography color="textSecondary">{totalChargeText}</Typography>
          }
        >
          <Typography variant="h5">
            <Price value={price} />
          </Typography>
        </DescriptionLineItem>
        <DescriptionLineItem
          title={
            <Typography color="textSecondary">Deposit Expected</Typography>
          }
        >
          <Typography variant="h5">
            <FormattedDate
              variant="Date"
              format="DateISO"
              date={data?.expected_date}
            />
          </Typography>
        </DescriptionLineItem>

        {nonSuperPayOrders > 0 && (
          <>
            <Divider />
            <Stack space="xsmall">
              <NonSuperPayOrdersInfo ordersCount={nonSuperPayOrders} />
              {isEqualNonSuperPayAndAllOrders && (
                <Typography color="textSecondary">
                  Inspection type will be changed to Advanced.
                </Typography>
              )}
            </Stack>
            {isEqualNonSuperPayAndAllOrders && <LongerTermsInfo />}
          </>
        )}
      </Stack>
    </DialogContent>
  );
}

function NonSuperPayOrdersInfo({ ordersCount }: { ordersCount: number }) {
  return (
    <Typography color="textSecondary">
      {ordersCount > 1
        ? `${ordersCount} orders change to`
        : `${ordersCount} order changes to`}{' '}
      <SuperPayLogo /> · 1-3 Business Days
    </Typography>
  );
}

function LongerTermsInfo() {
  return (
    <Columns space="xxsmall">
      <Column width="content">
        <Box position="relative" top="2px">
          <Info fontSize="small" color="action" />
        </Box>
      </Column>
      <Column>
        <Typography>
          Interested in paying with longer SuperPay terms? Please{' '}
          <Link
            color="primary"
            target="_blank"
            rel="noreferrer noopener"
            href="https://forms.gle/7uA3LAaxqVtDjNbW7"
          >
            click here.
          </Link>
        </Typography>
      </Column>
    </Columns>
  );
}
