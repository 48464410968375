import { usePhoneService } from '@superdispatch/phones';
import { useSnackbarStack } from '@superdispatch/ui';
import { useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  useMoovAcceptAPI,
  useMoovSyncAPI,
} from 'shared/helpers/superpay/MoovHelpers';
import { performNavigation } from 'shared/routing/NavigationBlock';
import { useShipperProfile } from 'shipper-profile/data/ShipperProfileAPI';
import { usePaymentLogger } from 'superpay/helpers/PaymentLogger';
import { businessSchema } from '../data/BusinessDTO';
import {
  useSuperPayAddendum,
  useUpdateBusinessContact,
} from '../data/SuperPayAPI';

function usePhoneNumber(phoneNumber: string) {
  const phoneService = usePhoneService();
  let phone = '';
  if (
    phoneNumber &&
    !phoneService.validate(phoneNumber) &&
    phoneService.getInfo(phoneNumber).country === 'US'
  ) {
    phone = phoneService.format(phoneNumber);
    phone = phoneService.deletePrefix(phone, 'US');
  }
  return phone;
}

function useDefaultValues(termsConditionsAccept: boolean) {
  const { data: profile } = useShipperProfile();
  const { name, address, zip, city, state, contact_phone, personal_page_url } =
    profile || {};

  const phoneNumber = usePhoneNumber(contact_phone || '');
  const website = personal_page_url || '';

  return useMemo(
    () => ({
      legalBusinessName: name && name.length <= 64 ? name : '',
      businessType: 'llc',
      einNumber: '',
      mccNumber: '4789',
      description: '',
      phoneNumber,
      website,
      streetAddress: address && address.length <= 60 ? address : '',
      zip: zip && zip.length === 5 ? zip : '',
      city: city && city.length <= 24 ? city : '',
      state: state && state.length === 2 ? state : '',
      termsConditionsAccept: !termsConditionsAccept,
    }),
    [
      address,
      city,
      name,
      phoneNumber,
      state,
      termsConditionsAccept,
      website,
      zip,
    ],
  );
}

interface BusinessDetailsFormArgs {
  onComplete: () => void;
}

export function useBusinessDetailsForm({
  onComplete,
}: BusinessDetailsFormArgs) {
  const { addSnackbar } = useSnackbarStack();
  const { logPaymentInfo } = usePaymentLogger();
  const { user } = useUserState();
  const { createSyncMoovAccount } = useMoovSyncAPI();
  const { createAcceptMoovAccount } = useMoovAcceptAPI();
  const { mutateAsync: updateProfileInformation } = useUpdateBusinessContact();
  const phoneService = usePhoneService();
  const { data: addendum } = useSuperPayAddendum();
  const isAddendumRequired = !!addendum?.superpay_addendum_required;
  const defaultValues = useDefaultValues(isAddendumRequired);

  return useAppFormik({
    initialValues: defaultValues,
    validationSchema: businessSchema,
    onSubmit: (data) => {
      if (!user) {
        return Promise.reject(
          new Error('Please try again or reload the page.'),
        );
      }

      const moovParams = {
        ...data,
        shipperGuid: user.shipper.guid,
        phoneCountryCode: '1',
        country: 'US',
      };

      void updateProfileInformation({
        ...data,
        phoneNumber: phoneService.format(data.phoneNumber),
      });

      if (!isAddendumRequired) {
        return createSyncMoovAccount(moovParams);
      }

      return createAcceptMoovAccount(moovParams);
    },
    onSubmitSuccess: () => {
      addSnackbar('Account created', { variant: 'success' });
      performNavigation(onComplete);
      logPaymentInfo('Submitted Business Details', 'BusinessDetailsForm');
    },
  });
}
