import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Button, Color, Inline, Stack } from '@superdispatch/ui';
import FileSaver from 'file-saver';
import { DateTime, LocalZone } from 'luxon';
import * as React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import APIErrorAlert from 'shared/api/utils/APIErrorAlert';
import { ExecutePermission } from 'shared/data/UserPermissions';
import { DownloadIcon, UploadIcon } from 'shared/icons/Icons';
import { ButtonGroupDropdown } from 'shared/ui/ButtonGroupDropdown';
import { CustomerDTO } from '../../shared/dto/CustomerDTO';
import { useCustomerRouteHelpers } from '../CustomersRouteHelpers';
import { useCustomersAPI } from '../data/CustomerAPI';
import { CustomersDeleteConfirmationForm } from './CustomersDeleteConfirmationForm';
import { CustomersSearchField } from './CustomersSearchField';

interface Props {
  customers: CustomerDTO[];
  onSelectAll: () => void;
  onDeselectAll: () => void;
  onDeleteSuccess: () => void;
  selectedCustomers: Set<number>;
}

export function CustomersListActions({
  customers: list,
  onSelectAll,
  onDeselectAll,
  onDeleteSuccess,
  selectedCustomers,
}: Props) {
  const navigate = useNavigate();
  const { exportCustomers } = useCustomersAPI();
  const { goToCustomerCreate } = useCustomerRouteHelpers();

  const exportForm = useFormikEnhanced<{}, Blob>({
    initialValues: {},
    onSubmit() {
      return exportCustomers(Array.from(selectedCustomers));
    },
    onSubmitSuccess(customersBlob) {
      const localZone = new LocalZone();
      const timeStamp = DateTime.local().setZone(localZone).toLocaleString({
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
      FileSaver.saveAs(customersBlob, `Customers - ${timeStamp}.csv`);
    },
    onSubmitFailure(error) {
      APIErrorAlert(error);
    },
  });

  const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onSelectAll();
    } else {
      onDeselectAll();
    }
  };

  return (
    <Stack space="small">
      <ExecutePermission
        entity="CUSTOMER_MANAGEMENT"
        fallback={
          <Button
            color="primary"
            variant="contained"
            fullWidth={true}
            isLoading={exportForm.isSubmitting}
            onClick={() => exportForm.handleSubmit()}
          >
            Export
          </Button>
        }
      >
        <ButtonGroupDropdown
          color="primary"
          variant="contained"
          startIcon={<Add />}
          fullWidth={true}
          label="Create New"
          isLoading={exportForm.isSubmitting}
          onClick={goToCustomerCreate}
          MenuListProps={{ disablePadding: true }}
        >
          <MenuItem onClick={goToCustomerCreate}>
            <Inline>
              <Add fontSize="small" color="action" />
              <Typography>Create New</Typography>
            </Inline>
          </MenuItem>

          <MenuItem onClick={() => navigate('/customers/import')}>
            <Inline>
              <DownloadIcon fontSize="small" color="action" />
              <Typography>Import</Typography>
            </Inline>
          </MenuItem>

          <MenuItem onClick={() => exportForm.handleSubmit()}>
            <Inline>
              <UploadIcon fontSize="small" color="action" />
              <Typography>Export</Typography>
            </Inline>
          </MenuItem>
        </ButtonGroupDropdown>
      </ExecutePermission>

      {selectedCustomers.size > 0 ? (
        <Box display="flex" alignItems="center">
          <Box ml="-6px" flex={1}>
            <Checkbox
              color="primary"
              aria-label="toggle all"
              onChange={handleSelection}
              indeterminate={selectedCustomers.size < list.length}
              checked={selectedCustomers.size === list.length}
            />
          </Box>

          <Box>
            <Tooltip title="Export">
              <IconButton onClick={() => exportForm.handleSubmit()}>
                {exportForm.isSubmitting ? (
                  <CircularProgress size="24px" />
                ) : (
                  <UploadIcon htmlColor={Color.Grey200} />
                )}
              </IconButton>
            </Tooltip>
          </Box>

          <ExecutePermission entity="CUSTOMER_MANAGEMENT">
            <Box mr="-6px">
              <CustomersDeleteConfirmationForm
                selectedCustomers={selectedCustomers}
                onDeleteSuccess={onDeleteSuccess}
              />
            </Box>
          </ExecutePermission>
        </Box>
      ) : (
        <CustomersSearchField />
      )}
    </Stack>
  );
}
