import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { useOrderCounts } from 'shared/api/OrderCountAPI';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useSelfServeOnlyProductTour } from 'shared/hooks/useProductTour';
import { AccessRestrictedDialog } from './core/actions/AccessRestrictedDialog';
import { OrderCreatePage } from './create/OrderCreatePage';
import { OrderEditPage } from './edit/OrderEditPage';
import { OrderImportPage } from './import/OrderImportPage';
import { OrdersListPage } from './list/OrdersListPage';
import { OrderRequestsPage } from './order-requests/OrderRequestsPage';
import { OrderPreviewPage } from './preview/OrderPreviewPage';
import { OrderViewPage } from './view/OrderViewPage';

const CombineOrdersPageLazy = lazy(
  () => import('./combine-orders/CombineOrdersPage'),
);

const OrderSplitLoadsPageLazy = lazy(
  () => import('./split-loads/OrderSplitLoadsPage'),
);

const OrderSplitPageLazy = lazy(() => import('./order-split/OrderSplitPage'));

export function OrdersContainer() {
  useTrackEvent('[STMS] Viewed Orders Page');
  useOrdersPageTour();
  return (
    <>
      <Routes>
        <Route path="create" element={<OrderCreatePage />} />
        <Route path="edit/:guid" element={<OrderEditPage />} />
        <Route
          path="view/:guid/*"
          element={<OrderViewPage isActive={true} path="/orders/view" />}
        />
        <Route
          path="inactive/view/:guid/*"
          element={
            <OrderViewPage isActive={false} path="/orders/inactive/view" />
          }
        />
        <Route path="split/:guid" element={<OrderSplitPageLazy />} />
        <Route path="split-loads/:guid" element={<OrderSplitLoadsPageLazy />} />
        <Route path="combine" element={<CombineOrdersPageLazy />} />
        <Route path="preview/:type/:orderID" element={<OrderPreviewPage />} />
        <Route path="import" element={<OrderImportPage />} />
        <Route path="requests" element={<OrderRequestsPage />} />
        <Route path=":status?" element={<OrdersListPage />} />
      </Routes>
      <AccessRestrictedDialog />
    </>
  );
}

function useIsFirstOrderPostedToLb() {
  const { data: orderCounts } = useOrderCounts();

  if (!orderCounts) {
    return false;
  }

  const countersThatShouldBeZero: Array<keyof typeof orderCounts> = [
    'new',
    'pending',
    'declined',
    'accepted',
    'picked_up',
    'delivered',
    'invoiced',
    'paid',
    'canceled',
    'requests',
  ];

  return (
    orderCounts.posted_to_lb > 0 &&
    countersThatShouldBeZero.every((key) => orderCounts[key] === 0)
  );
}

function useOrdersPageTour() {
  const { data: orderCounts } = useOrderCounts();

  useSelfServeOnlyProductTour({
    tourKey: 'self_serve_reboot_orders_page',
    enabled: orderCounts?.total === 0,
  });

  useSelfServeOnlyProductTour({
    tourKey: 'self_serve_reboot_orders_page_highlight_requests',
    enabled: useIsFirstOrderPostedToLb(),
  });
}
