import { Typography } from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { Banner, Button } from '@superdispatch/ui-lab';
import { useUnratedCarriersCount } from 'core/ratings/data/CarrierRatingAPI';
import { useNavigate } from 'react-router-dom-v5-compat';

export function OrderListRateBanner() {
  const navigate = useNavigate();
  const { data } = useUnratedCarriersCount();
  return (
    <Banner
      in={data && data.unrated_count > 0}
      border="top"
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <Inline verticalAlign="center" horizontalAlign="center" space="small">
        <Typography>
          Take a moment to leave a rating for carriers you haven&apos;t reviewed
          yet.
        </Typography>
        <Button
          onClick={() => {
            navigate(
              '/profile?tab=ratingsToGive&utm_medium=Rate Carriers Banner',
            );
          }}
          variant="neutral"
        >
          Rate Carriers
        </Button>
      </Inline>
    </Banner>
  );
}
