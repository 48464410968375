import { IconButton, Link } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import { Color, Stack } from '@superdispatch/ui';
import { DescriptionItem, LinkedText } from '@superdispatch/ui-lab';
import { normalizeURL } from '@superdispatch/uri';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom-v5-compat';
import { useTryBack } from 'shared/helpers/RouteHelpers';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { HashtagIcon } from 'shared/icons/HashtagIcon';
import { LocationIcon as IconLocation } from 'shared/icons/LocationIcon';
import { WebIcon as IconWeb } from 'shared/icons/WebIcon';
import { MultilineTextView } from 'shared/ui/MultilineTextView';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import { isEmpty } from '../data/ShipperProfileUtils';
import { EmptySection, SectionLayout } from '../SectionLayout';
import { AboutUsEditDrawer } from './AboutUsEditDrawer';

interface AboutUsSectionProps {
  profile: ShipperProfileDTO;
  onUpdate?: () => void;
}

export function AboutUsSection({ profile, onUpdate }: AboutUsSectionProps) {
  const { about_us, in_business_since, personal_page_url, usdot_number } =
    profile;
  const navigate = useNavigate();
  const { tryBack } = useTryBack();
  const drawerMatch = useMatch('/profile/about-us/edit');
  const isContentEmpty = useMemo(
    () =>
      isEmpty(profile, [
        'about_us',
        'address',
        'in_business_since',
        'personal_page_url',
        'usdot_number',
      ]),
    [profile],
  );
  const openDrawer = () => navigate('/profile/about-us/edit');
  const closeDrawer = () => tryBack({ pathname: '/profile' });

  const fullAddress = ShipperProfileDTO.formatAddress(profile);
  const personalPageURL = useMemo(
    () => normalizeURL(personal_page_url),
    [personal_page_url],
  );

  return (
    <>
      <AboutUsEditDrawer
        profile={profile}
        open={!!drawerMatch}
        onClose={closeDrawer}
        onUpdate={onUpdate}
      />

      {isContentEmpty ? (
        <EmptySection onClick={openDrawer} startIcon={<Add />}>
          Add About Us
        </EmptySection>
      ) : (
        <SectionLayout
          title="About Us"
          editComponent={
            <IconButton
              aria-label="edit about us"
              size="small"
              onClick={openDrawer}
            >
              <Edit />
            </IconButton>
          }
        >
          <Stack space="small">
            {about_us ? (
              <MultilineTextView bgcolor={Color.Blue50} p={1}>
                <LinkedText>{about_us}</LinkedText>
              </MultilineTextView>
            ) : null}

            <Stack space="xxsmall">
              {in_business_since && (
                <DescriptionItem
                  icon={<CalendarIcon />}
                  label="In business since"
                >
                  {DateTime.fromFormat(
                    in_business_since,
                    'yyyy.MM',
                  ).toLocaleString({
                    month: 'long',
                    year: 'numeric',
                  })}
                </DescriptionItem>
              )}
              {usdot_number && (
                <DescriptionItem icon={<HashtagIcon />} label="USDOT">
                  {usdot_number}
                </DescriptionItem>
              )}
              {personalPageURL && (
                <DescriptionItem icon={<IconWeb />}>
                  <Link
                    href={personalPageURL}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {personal_page_url}
                  </Link>
                </DescriptionItem>
              )}
              {fullAddress.length > 0 && (
                <DescriptionItem icon={<IconLocation />}>
                  {fullAddress}
                </DescriptionItem>
              )}
            </Stack>
          </Stack>
        </SectionLayout>
      )}
    </>
  );
}
