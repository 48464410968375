import { SidebarContainer } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { TerminalCreatePage } from './create/TerminalCreatePage';
import { TerminalDetailsPage } from './details/TerminalDetailsPage';
import { TerminalEmptyState } from './list/TerminalEmptyState';
import { TerminalsList } from './list/TerminalsList';
import { useTerminals } from './list/TerminalsListState';
import { TerminalsSelection } from './list/TerminalsSelection';
import { TerminalsImportPage } from './TerminalsImportPage';
import { useTerminalsNavigation } from './TerminalsRouteHelpers';

export function TerminalsPage() {
  useDocumentTitle('Terminals');
  useTrackEvent('[STMS] Viewed Terminals Page');

  const {
    data,
    list: terminals = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useTerminals();

  const {
    terminalsSearchParams: { query },
  } = useTerminalsNavigation();

  const [selectedTerminals, setSelectedTerminals] = useState(
    () => new Set<number>(),
  );

  function getTotalTerminalsCount() {
    const lastPage = data?.pages[data.pages.length - 1];
    return lastPage?.pagination.total_objects ?? 0;
  }

  return (
    <PageLayout>
      <SidebarContainer
        sidebar={
          <TerminalsList
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            terminals={terminals}
            total={getTotalTerminalsCount()}
            selectedTerminals={selectedTerminals}
            onSelectedTerminalsChange={setSelectedTerminals}
          />
        }
      >
        <Routes>
          <Route path="create" element={<TerminalCreatePage />} />
          <Route path="import" element={<TerminalsImportPage />} />
          <Route
            path="*"
            element={
              selectedTerminals.size ? (
                <TerminalsSelection
                  count={selectedTerminals.size}
                  onClear={() => {
                    setSelectedTerminals(new Set());
                  }}
                />
              ) : !query && terminals.length === 0 ? (
                <TerminalEmptyState />
              ) : (
                <TerminalDetailsPage terminals={terminals} />
              )
            }
          />
        </Routes>
      </SidebarContainer>
    </PageLayout>
  );
}
