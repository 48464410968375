import { AppBar, Link, Toolbar, Typography } from '@material-ui/core';
import { Box } from '@rebass/grid';
import { FormikTextField } from '@superdispatch/forms';
import {
  Button,
  Color,
  Column,
  Columns,
  InfoTooltip,
  Stack,
} from '@superdispatch/ui';
import { Form, useFormikContext } from 'formik';
import { Link as RouteLink } from 'react-router-dom-v5-compat';
import { PageLayout } from 'shared/layout/PageLayout';
import { email, required } from 'shared/utils/ValidatorUtils';

interface InternalCarrierFormProps {
  title: string;
}

export function InternalCarrierForm({ title }: InternalCarrierFormProps) {
  const { isSubmitting } = useFormikContext();
  return (
    <Form noValidate={true}>
      <PageLayout
        header={
          <AppBar>
            <Toolbar>
              <Columns>
                <Column width="fluid">
                  <Typography variant="h3">{title}</Typography>
                  <Link
                    color="primary"
                    component={RouteLink}
                    to="/manage-carriers?carrier_type=internal"
                  >
                    ← Manage Carriers
                  </Link>
                </Column>

                <Column width="content">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    isLoading={isSubmitting}
                  >
                    Save and Close
                  </Button>
                </Column>
              </Columns>
            </Toolbar>
          </AppBar>
        }
      >
        <div style={{ background: Color.White }}>
          <Box width={500} m={4}>
            <Stack>
              <h3>Carrier Information</h3>

              <FormikTextField
                name="name"
                label={
                  <>
                    <span>Company Name</span>{' '}
                    <span style={{ color: '#848a96' }}>(Required)</span>
                  </>
                }
                fullWidth={true}
                validate={required}
                autoFocus={true}
                inputProps={{ maxLength: 250 }}
              />

              <FormikTextField
                name="address"
                label="Address"
                fullWidth={true}
                inputProps={{ maxLength: 500 }}
              />

              <Box width={2 / 7}>
                <FormikTextField
                  name="zip"
                  label="ZIP"
                  fullWidth={true}
                  inputProps={{ maxLength: 50 }}
                />
              </Box>

              <Box width={4 / 7}>
                <FormikTextField
                  name="city"
                  label="City"
                  fullWidth={true}
                  inputProps={{ maxLength: 100 }}
                />
              </Box>

              <Box width={1 / 7}>
                <FormikTextField
                  name="state"
                  label="State"
                  fullWidth={true}
                  inputProps={{ maxLength: 50 }}
                />
              </Box>

              <Box width="100%">
                <FormikTextField
                  name="broker_records.custom_external_id"
                  inputProps={{ maxLength: 100 }}
                  fullWidth={true}
                  label={
                    <InfoTooltip title="This field can be used to store any information and available through API">
                      Custom External ID
                    </InfoTooltip>
                  }
                />
              </Box>

              <Box width="100%" mt={3}>
                <h3>Contact Information</h3>
              </Box>

              <Box width="100%">
                <FormikTextField
                  name="contact_name"
                  label="Contact Name"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                />
              </Box>

              <Box width="100%">
                <FormikTextField
                  name="email"
                  label="Email"
                  validate={email}
                  fullWidth={true}
                  inputProps={{ maxLength: 100 }}
                />
              </Box>

              <Box width="100%">
                <FormikTextField
                  name="phone_numbers"
                  label="Phone"
                  fullWidth={true}
                  inputProps={{ maxLength: 150 }}
                />
              </Box>

              <Box width="100%">
                <FormikTextField
                  name="fax"
                  label="Fax"
                  fullWidth={true}
                  inputProps={{ maxLength: 150 }}
                />
              </Box>
            </Stack>
          </Box>
        </div>
      </PageLayout>
    </Form>
  );
}
