import { useFormikEnhanced } from '@superdispatch/forms';
import { Button, Inline, useSnackbarStack } from '@superdispatch/ui';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CarrierFullInfo } from 'shared/types/carrier';
import ConfirmationButton from 'shared/ui/ConfirmationButton';
import { useCarrierProfileAPI } from '../data/ManageCarriersAPI';

interface Props {
  carrier: CarrierFullInfo;
}

export function ManageCarriersTableAction({ carrier }: Props) {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const { removeInternalCarrier } = useCarrierProfileAPI();
  const { handleSubmit, isSubmitting } = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => removeInternalCarrier(carrier.guid),
    onSubmitSuccess() {
      addSnackbar('Carrier removed successfully', { variant: 'success' });
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Inline space="xxsmall">
      <Button
        variant="outlined"
        color="primary"
        onClick={() => navigate(`/manage-carriers/edit/${carrier.guid}`)}
      >
        Edit
      </Button>

      <ConfirmationButton
        dialogContent="Are you sure you want to delete the carrier?"
        isLoading={isSubmitting}
        confirmBtnProps={{ color: 'error' }}
        onConfirm={() => handleSubmit()}
      >
        Delete
      </ConfirmationButton>
    </Inline>
  );
}
