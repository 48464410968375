import dates from '@superdispatch/dates';
import { PaymentMethod, PAYMENT_METHODS } from '@superdispatch/sdk';
import { Exclude, Expose, Transform } from 'class-transformer';
import {
  AllPaymentTerm,
  ALL_PAYMENT_TERMS,
} from 'shared/dto/Order/CarrierPaymentDTO';
import {
  TransformDateString,
  TransformEnum,
} from 'shared/utils/TransformUtils';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export interface Referral {
  name: string | null;
  contact_name: string | null;
  phone: string | null;
}

@Exclude()
export class RatingStatsDTO {
  @Expose() total_rating_count: number | null;
  @Expose() overall_rating: number | null;
}

@Exclude()
export class CarrierInsuranceRequirementsDTO {
  @Expose() trailer_size: number | null;
  @Expose() cargo_limit: number | null;
}

@Exclude()
export class ShipperProfileDTO {
  static formatAddress({ address, city, state, zip }: ShipperProfileDTO) {
    return [address, city, state, zip].filter(Boolean).join(', ');
  }

  @Expose() about_us: string | null;
  @Expose() address: string | null;
  @Expose() agent_phone: string | null;
  @Expose() alternative_payment_methods: PaymentMethod[] | null;
  @Expose() billing_contact_name: string | null;
  @Expose() billing_email: string | null;
  @Expose() billing_phone: string | null;
  @Expose() bond_agent: string | null;
  @Expose() bond_company: string | null;
  @Expose() carrier_requirements: string | null;
  @Expose() central_dispatch_webhook_email: string | null;
  @Expose() is_instant_booking_allowed_for_verified_carriers: boolean;
  @Expose() is_auto_repost_for_decline_offers_enabled: boolean;
  @Expose() is_auto_repost_for_cancel_offers_enabled: boolean;
  @Expose() hide_contact_info_for: 'ANY' | null;
  @Expose() private_loadboard_duration: string;
  @Expose() posts_on_multiple_loadboards: boolean;
  @Expose() changed_at: string | null;
  @Expose() city: string | null;
  @Expose() contact_email: string;
  @Expose() contact_name: string;
  @Expose() contact_phone: string | null;
  @Expose() created_at: string | null;
  @Expose() current_load_id: number | null;
  @Expose() default_bol_template: string | null;
  @Expose() default_inspection_type: string | null;

  @Expose()
  @TransformEnum(ALL_PAYMENT_TERMS)
  default_payment_terms: AllPaymentTerm | null;

  @Expose()
  @TransformEnum(PAYMENT_METHODS)
  default_payment_method: PaymentMethod | null;

  @Expose() fax: string | null;
  @Expose() guid: string | null;
  @Expose() id: number;
  @Expose() in_business_since: string | null;
  @Expose() is_active: boolean;
  @Expose() is_test_account: boolean;
  @Expose() load_id_prefix: string | null;
  @Expose() logo_url: string | null;
  @Expose() name: string;
  @Expose() overall_moved_vehicles_count: number | null;
  @Expose() personal_page_url: string | null;
  @Expose() preferred_payment_methods: PaymentMethod[] | null;
  @Expose() registration_date: string | null;
  @Expose() shipper_type: string | null;
  @Expose() six_month_moved_vehicles_count: number | null;
  @Expose() source: string | null;
  @Expose() state: string | null;
  @Expose() status: string | null;
  @Expose() subscription_plan: string | null;
  @Expose() terms_and_conditions: string | null;
  @Expose() time_zone: string | null;
  @Expose() trial_period_end_date: null;
  @Expose() usdot_number: number | null;
  @Expose() zip: string | null;
  @Expose() ach_payments: boolean;
  @Expose() carrier_certificate_of_insurance:
    | 'CERTIFICATE_HOLDER'
    | 'ADDITIONALLY_INSURED'
    | null;

  @Expose()
  @Transform((value) => value || [])
  referrals: Referral[];

  @Expose()
  @TransformDateString('DateISO')
  bond_expiration_date: dates.NullableDateString;

  @Expose() rating_stats?: RatingStatsDTO | null;

  @Expose()
  carrier_insurance_requirements?: CarrierInsuranceRequirementsDTO[] | null;

  @Expose()
  verification_status:
    | 'NOT_SUBMITTED'
    | 'PENDING'
    | 'WAITING_FOR_CUSTOMER'
    | 'INTERNATIONAL_SHIPPER'
    | 'REJECTED'
    | 'REVOKED'
    | 'VERIFIED';
}

export type CentralIntegrationFormDTO = InferType<
  typeof centralIntegrationFormSchema
>;
export const centralIntegrationFormSchema = yupObject({
  centraldispatch_uid: yupString()
    .nullable()
    .transform((value) => (value === '' ? null : value))
    .default(null)
    .test(
      'isUidValid',
      'UID must not include symbols (@, #, -, &, etc.)',
      (uid) => {
        return !uid || /^[a-zA-Z0-9]+$/.test(uid);
      },
    )
    .min(6, 'UID must be 6 to 9 characters long.')
    .max(9, 'UID must be 6 to 9 characters long.'),
  centraldispatch_login: yupString()
    .nullable()
    .default(null)
    .test('isNoWhitespace', 'Username must not include spaces', (login) => {
      return !login || !/\s/.test(login);
    }),
  centraldispatch_password: yupString()
    .nullable()
    .default(null)
    .test('isNoWhitespace', 'Password must not include spaces', (login) => {
      return !login || !/\s/.test(login);
    }),
}).noUnknown();
