import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import styled from 'styled-components';

function hasFormHash(hash: string) {
  return hash.startsWith('#open-compliance-form-');
}

function getFormId(hash: string) {
  return hash.replace('#open-compliance-form-', '');
}

function isValidFormId(formId: string) {
  return formId.length > 5 && /\d/.test(formId);
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;

const StyledDialogContent = styled(DialogContent)`
  height: 100vh;
`;

function ComplianceFormDialogContent({ formId }: { formId: string }) {
  const counterRef = useRef(0);

  useEffect(() => {
    trackEvent('Opened Verification Application');
  }, []);

  return (
    <StyledDialogContent dividers={true}>
      {isValidFormId(formId) ? (
        <Iframe
          onLoad={() => {
            // Track form submit inside iframe. When form is submitted onLoad is fired 2nd time
            if (++counterRef.current === 2) {
              trackEvent('Submitted Verification Application');
            }
          }}
          src={`https://superdispatch.formtitan.com/ftproject/shippersetup?cfid=${formId}`}
        />
      ) : (
        <Container>
          <div>
            <Stack align="center">
              <CircularProgress size={20} />
              <TextBox align="center" variant="heading-2">
                Next Step in Progress: <br /> Please Return Later
              </TextBox>
              <TextBox align="center" color="secondary">
                This process may take a little longer, possibly up to 10
                minutes. <br />
                You&apos;re welcome to step away and return soon. <br />
                Thank you for your patience!
              </TextBox>
            </Stack>
          </div>
        </Container>
      )}
    </StyledDialogContent>
  );
}

export function ComplianceFormDialog() {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Dialog open={hasFormHash(hash)} maxWidth="md" fullWidth={true}>
      <DialogTitle>
        <Columns>
          <Column>
            <TextBox variant="heading-2">Verification Application</TextBox>
          </Column>
          <Column width="content">
            <IconButton
              size="small"
              onClick={() => {
                navigate(pathname, { replace: true });
              }}
            >
              <Close />
            </IconButton>
          </Column>
        </Columns>
      </DialogTitle>

      <ComplianceFormDialogContent formId={getFormId(hash)} />
    </Dialog>
  );
}
