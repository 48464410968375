import { stringify } from 'qs';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const terminalsSearchParamsSchema = yupObject({
  current_terminal_guid: yupString().nullable(),
  query: yupString().nullable(),
});

type TerminalsSearchParams = InferType<typeof terminalsSearchParamsSchema>;

export function useTerminalsNavigation() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const terminalsSearchParams = useMemo(
    () => terminalsSearchParamsSchema.cast(Object.fromEntries(searchParams)),
    [searchParams],
  );

  function navigateToTerminals(
    newSearchParams?: Partial<TerminalsSearchParams>,
    options: {
      replace?: boolean;
    } = {},
  ) {
    navigate(
      {
        pathname: '/terminals',
        search: newSearchParams ? stringify(newSearchParams) : undefined,
      },
      options,
    );
  }

  function navigateToTerminalsCreate() {
    navigate('/terminals/create');
  }

  function navigateToTerminalsImport() {
    navigate('/terminals/import');
  }

  return {
    terminalsSearchParams,
    navigateToTerminals,
    navigateToTerminalsCreate,
    navigateToTerminalsImport,
  };
}
