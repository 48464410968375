import { Box, Card, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { SidebarContent } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { TerminalDTO } from 'shared/dto/TerminalDTO';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { useTerminal } from '../data/TerminalsAPI';
import { useTerminalsNavigation } from '../TerminalsRouteHelpers';
import { TerminalContactList } from './TerminalContactList';
import { TerminalDeleteConfirmation } from './TerminalDeleteConfirmation';
import { TerminalDetailsInfoCard } from './TerminalDetailsInfoCard';
import { TerminalDetailsInfoDrawer } from './TerminalDetailsInfoDrawer';
import { TerminalDetailsNotesCard } from './TerminalDetailsNotesCard';
import { TerminalDetailsNotesDrawer } from './TerminalDetailsNotesDrawer';

export function TerminalDetailsPage({
  terminals,
}: {
  terminals: TerminalDTO[];
}) {
  const [modal, setModal] = useState<'details' | 'notes'>();
  const {
    terminalsSearchParams: { current_terminal_guid },
    navigateToTerminals,
  } = useTerminalsNavigation();

  const {
    error,
    isLoading,
    data: terminal,
  } = useTerminal({
    guid: current_terminal_guid,
  });

  useEffect(() => {
    if (!terminal && !isLoading && !!terminals[0]) {
      navigateToTerminals(
        {
          current_terminal_guid: terminals[0].guid as string,
        },
        {
          replace: true,
        },
      );
    }
  }, [terminals, isLoading, navigateToTerminals, terminal]);

  if (!current_terminal_guid) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h2">No terminal selected</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h2">
          <APIErrorMessage error={error} />
        </Typography>
      </Box>
    );
  }

  if (!terminal) {
    return (
      <SidebarContent title={<Skeleton width="130px" />}>
        <Columns collapseBelow="desktop" space="small">
          <Column width="1/3">
            <Stack space="small">
              <Card>
                <Skeleton variant="rect" height="192px" />
              </Card>

              <Card>
                <Skeleton variant="rect" height="104px" />
              </Card>
            </Stack>
          </Column>

          <Column width="2/3">
            <Card>
              <Skeleton variant="rect" height="172px" />
            </Card>
          </Column>
        </Columns>
      </SidebarContent>
    );
  }

  return (
    <SidebarContent
      title={terminal.name}
      action={<TerminalDeleteConfirmation terminal={terminal} />}
    >
      <Columns collapseBelow="desktop" space="small">
        <Column width="1/3">
          <Stack space="small">
            <TerminalDetailsInfoCard
              terminal={terminal}
              onEdit={() => {
                setModal('details');
              }}
            />

            <TerminalContactList terminal={terminal} />
          </Stack>
        </Column>

        <Column width="2/3">
          <TerminalDetailsNotesCard
            terminal={terminal}
            onEdit={() => {
              setModal('notes');
            }}
          />
        </Column>
      </Columns>

      <TerminalDetailsInfoDrawer
        open={modal === 'details'}
        terminal={terminal}
        onClose={() => {
          setModal(undefined);
        }}
      />

      <TerminalDetailsNotesDrawer
        open={modal === 'notes'}
        terminal={terminal}
        onClose={() => {
          setModal(undefined);
        }}
      />
    </SidebarContent>
  );
}
